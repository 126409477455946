export const RULE_CONFIG = {
  BUILDING_CONSTRAINTS_PER_CITY: {
    Warehouse: 8,
    Barracks: 1,
    Mapchamber: 1,
    Embassy: 1,
    Marketplace: 1,
    Mill: 1,
    Lumberyard: 1,
    Quarry: 1,
    Smith: 1,
    Brothel: 1,
    Casino: 1,
  },
  allianceMemberMultiplier: 2,
  merchantsTravelSpeed: 8,
  merchantCarryAmount: 10000,
  maxMerchantAmount: 20,
  default_buildings_in_queue: 2,
};

export const CASINO_COINS_BUY_PRICE = {
  Wheat: 2720,
  Wood: 2740,
  Stone: 2690,
  Iron: 2760,
};

export const CASINO_COINS_SELL_PRICE = {
  Wheat: 2650,
  Wood: 2700,
  Stone: 2620,
  Iron: 2740,
};

export const AchievementDetails = {
  attacksSendtGoal: [5, 20, 50, 100, 200, 500, 1000],
  citiesFoundedGoal: [1, 2, 4, 7, 12, 17, 25],
  listingsSoldGoal: [5, 20, 50, 100, 200, 500, 1000],
  resourcesSpentOnStructuresGoal: [
    5000, 15000, 40000, 75000, 120000, 200000, 500000,
  ],
  resourcesStolenGoal: [1000, 2000, 5000, 15000, 50000, 100000, 500000],
  troopsKilledGoal: [20, 50, 200, 500, 1000, 2500, 5000],
};

export const WAREHOUSE_BONUS_LEVEL = [
  400, 657, 919, 1206, 1625, 2002, 2526, 3150, 3602, 4231, 5210, 6332, 7434,
  8608, 11713, 16319, 22734, 31673, 44126, 61474, 85644, 119316, 166228, 231583,
  322633, 445860, 622582, 868784, 1211786, 1689643,
];

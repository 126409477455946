import {TabItem} from "./Marketplace";
import {useContext, useState} from "react";
import {HighScoreNavDropDown} from "../lists/HighScoreNavDropDown";
import {SkillTree} from "../skilltrees/SkillTree";
import {SkillContext} from "../../index";
import {BuildingDescAndImage} from "../cards/BuildingDescAndImage";

export function Church({level, hideUpgradeMenu}){
    const { skillInfo } = useContext(SkillContext);
    const [activeTab, setActiveTab] = useState("overview");

    const options = [
        { value: "overview", text: "Overview" },
        { value: "army", text: "Army" },
        { value: "farmer", text: "Farmer" },
        { value: "worker", text: "Worker" },
        { value: "gambler", text: "Gambler" },
    ];

    const handleTabChange = (tab) => {
        if(tab === "overview"){
            hideUpgradeMenu(true)
        }else{
            hideUpgradeMenu(false)
        }
        setActiveTab(tab);
    };

    return <div>
        <div className="mb-4">
            <ul className="md:flex hidden border-b">
                <TabItem
                    label="Overview"
                    active={activeTab === "overview"}
                    onClick={() => handleTabChange("overview")}
                />
                <TabItem
                    label="Army"
                    active={activeTab === "army"}
                    onClick={() => handleTabChange("army")}
                />
                <TabItem
                    label="Farmer"
                    active={activeTab === "farmer"}
                    onClick={() => handleTabChange("farmer")}
                />
                <TabItem
                    label="Worker"
                    active={activeTab === "worker"}
                    onClick={() => handleTabChange("worker")}
                />
                <TabItem
                    label="Gambler"
                    active={activeTab === "gambler"}
                    onClick={() => handleTabChange("gambler")}
                />

            </ul>
            <div className="flex justify-center mb-2 z-[1000]">
                <HighScoreNavDropDown options={options} onOptionChange={(value) => handleTabChange(value)} showOption={activeTab}/>
            </div>
        </div>
        {activeTab === "overview" && <BuildingDescAndImage type="Church" isDetails={true}/> }
        {activeTab === "army" && <SkillTree tree_type="army" progress={skillInfo.army}/>}
        {activeTab === "farmer" && <SkillTree tree_type="farmer" progress={skillInfo.farmer}/>}
        {activeTab === "worker" && <SkillTree tree_type="worker" progress={skillInfo.worker}/>}
        {activeTab === "gambler" && <SkillTree tree_type="gambler" progress={skillInfo.gambler}/>}
    </div>
}
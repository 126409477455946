import React from "react";

export function SkillTreeProgressBar({ level, xp, next_goal }) {
  // Calculate the percentage of the bar to fill
  const percentage = next_goal !== "MAXED" ? (xp / next_goal) * 100 : next_goal;

  return (
    <div className="w-full bg-gray-300 rounded-full h-4 mb-8">
      <div
        className="bg-blue-500 h-4 rounded-full"
        style={{ width: `${percentage}%` }}
      />
      {next_goal !== "MAXED" ? (
        <div className="flex justify-between mt-1 text-sm text-gray-700">
          <span>Level {level}</span>
          <span>
            {xp} / {next_goal} XP
          </span>
        </div>
      ) : (
        <div className="flex justify-between">
          <span>
            Level {level}({next_goal})
          </span>
          <span>{xp} xp gained.</span>
        </div>
      )}
    </div>
  );
}

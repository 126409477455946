import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";

export function RegisterPage() {
  const [user, setUser] = useState({
    userName: "",
    password: "",
    confirm_password: "",
    email: "",
    startingArea: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();

  async function registerNow(e) {
    e.preventDefault();
    setError("");

    if (user.password !== user.confirm_password) {
      setError("Passwords do not match!");
      return;
    }
    if (
      user.userName.length > 0 &&
      user.password.length > 0 &&
      user.email.length > 0 &&
      user.startingArea.length > 0
    ) {
      const res = await fetch(
        `${import.meta.env.VITE_GAMEHOST}/api/auth/register`,
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
          },

          body: JSON.stringify(user),
          credentials: "include",
        }
      );

      if (res.status !== 200) {
        setError(await res.text());
      } else {
        navigate("/");
      }
    } else {
      setError("Please fill in all the fields");
    }
  }

  function handleEmailChange(event) {
    setUser({ ...user, email: event.target.value });
  }

  function handleUserNameChange(event) {
    if (user.userName.length >= 15) {
      setError("Max 15 character username!");
      return;
    }
    setUser({ ...user, userName: event.target.value });
  }

  function handlePasswordChange(event) {
    setUser({ ...user, password: event.target.value });
  }
  function handleConfirmPasswordChange(event) {
    setUser({ ...user, confirm_password: event.target.value });
  }

  function handleStartingAreaChange(event) {
    setUser({ ...user, startingArea: event.target.value });
  }

  return (
    <div className="h-screen w-screen flex flex-col bg-gradient-to-b from-mainLightGreen to-[#d4a373]">
      <SignedOutsNavBar />
      <div className="bg-cover bg-center mb-auto  flex items-center justify-center h-full text-black ">
        <div className="bg-mainDarkGreen bg-opacity-80 p-10 rounded-lg w-full sm:w-[500px] h-full sm:h-fit max-w-md ">
          <h2 className="text-4xl font-bold mb-5">Register user</h2>
          <form onSubmit={registerNow}>
            <div>
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="email"
                placeholder="email"
                id="emailInput"
                name="emailInput"
                onChange={(e) => handleEmailChange(e)}
                required
              />
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="text"
                placeholder="username"
                name="usernameInput"
                id="usernameInput"
                onChange={(e) => handleUserNameChange(e)}
                maxLength={15}
                required
              />
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="password"
                placeholder="password"
                name="password1Input"
                id="password1Input"
                onChange={(e) => handlePasswordChange(e)}
                required
              />
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="password"
                placeholder="Confirm password"
                name="password2Input"
                id="password2Input"
                onChange={(e) => handleConfirmPasswordChange(e)}
                required
              />
              <select
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg mb-5"
                name="Starting area"
                onChange={(e) => handleStartingAreaChange(e)}
              >
                <option value="" selected disabled>
                  Select your starting area!
                </option>
                <option value="Woods">Woods</option>
                <option value="Mountains">Mountains</option>
                <option value="Snowlands">Snowlands</option>
                <option value="Savanna">Savanna</option>
              </select>
              <div className="mb-5">
                <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800">
                  Register
                </button>
              </div>
              <div>
                <h4 className="mb-2 text-xl">Already signed up?</h4>
                <Link
                  className="text-blue-600 hover:underline text-xl"
                  to="/login"
                >
                  {" "}
                  Back to login
                </Link>
              </div>
            </div>
          </form>
          {error && <div className="mt-4 text-red-700">{error}</div>}
        </div>
      </div>
      <SignedOutFooter />
    </div>
  );
}

import { useContext, useEffect, useState } from "react";

import {
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index.jsx";

import { useNavigate } from "react-router-dom";
import { ResFieldNextLevelInfo } from "../cards/ResFieldNextLevelInfo";
import {
  BUILDINGS_AND_PRICES,
  RESOURCE_FIELD_DESCRIPTIONS,
} from "../../constants";
import { ResFieldInfoImage } from "../../images/resourceFields/ResFieldInfoImage";
import { NextLevelRequirementsCard } from "../cards/NextLevelRequirementsCard";
import {
  useResourceUpgradeTimerState,
  useTimerDispatch,
} from "../contexts/timerContext";
import { timerActions } from "../reducers/timerReducer";
import { toast } from "react-toastify";

export function ResourceBox() {
  const [status, setStatus] = useState();
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId, cityProductionStats, cityBonus } =
    useContext(CityConfigContext);
  const { selectedResField } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const { setShowSpinner } = useContext(UserContext);

  const [actualBuildTime, setActualBuildTime] = useState(99999999);
  const [resourceField, setResourceField] = useState();
  const resourceTimers = useResourceUpgradeTimerState();

  const [hasEnoughResources, setHasEnoughResources] = useState(false);
  const [isMaxLevel, setIsMaxLevel] = useState(false);
  const [nextLevelInfo, setNextLevelInfo] = useState();
  const navigate = useNavigate();
  const [nextField, setNextField] = useState();

  useEffect(() => {
    const selectedField = localStorage.getItem("selectedResField");
    const data = JSON.parse(selectedField);
    if (resourceTimers.length > 0) {
      if (data.nextLevel == null) {
        resourceTimers.map((item) => {
          if (item.location === data.location) {
            data.nextLevel = item.extra.next_level;
            data.status = `${data.level} -> ${item.extra.next_level}`;
          }
        });
      }
    }
    setResourceField(data);
  }, [selectedResField]);

  /*   async function getFieldReq(level, type) {
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/resource/field/req/${level}/${type}/${inCityId}`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      const data = await res.json();

      if (cityBonus) {
        const bonus = cityBonus.building_speed_bonus / 100;
        const timeToBuild = Math.round(
          data.reqs.seconds_to_complete / (1 + bonus)
        );

        setActualBuildTime(timeToBuild);
      } else {
        setActualBuildTime(data.reqs.seconds_to_complete);
      }

      setNextLevelInfo(data.nextLevelInfo);
      setResourceReq(data.reqs);
    } else if (res.status === 204) {
      setIsMaxLevel(true);
    }
  } */
  const build = async (newFieldId, instaComplete) => {
    setStatus(undefined);
    setShowSpinner(true);
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/resource/upgrade?insta_complete=${instaComplete}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          location: resourceField.location,
          cityId: inCityId,
          building_name: nextField.type,
          next_level: nextField.level,
        }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      // No instacomplete
      navigate("/resources");

      const data = await res.json();
      dispatch({
        type: timerActions.ADD_TIMER,
        category: "resourceTimers",
        payload: {
          duration: data.building_total_time,
          location: data.location,
          timeLeft: data.timeLeft,
          endTime: data.end_time,
          extra: {
            building_name: data.building_name,
            next_level: data.building_level,
          },
        },
      });
      if (cityBonus.insta_building_complete_chance > 0) {
        toast(`YOU ROLLED ${data.roll}`);
      }
    } else if (res.status === 201) {
      // Did instacomplete
      const data = await res.json();
      if (cityBonus.insta_building_complete_chance > 0) {
        toast(`YOU ROLLED ${data.roll}, Building insta completed.`);
      } else {
        toast("Building has been insta completed!");
      }

      navigate("/resources");
    } else if (res.status === 401) {
      const data = await res.text();
      setStatus(data);
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  };

  /*   useEffect(() => {
    if (resourceReq) {
      if (
        warehouse.wheat >= resourceReq.wheat_req &&
        warehouse.wood >= resourceReq.wood_req &&
        warehouse.stone >= resourceReq.stone_req &&
        warehouse.iron >= resourceReq.iron_req
      ) {
        setHasEnoughResources(true);
      }
    }
  }, [warehouse, resourceReq]); */

  useEffect(() => {
    if (resourceField && warehouse) {
      let nextLevel = resourceField.level;
      const matchingFields = resourceTimers.filter(
        (field) =>
          field.extra.building_name === resourceField.type &&
          field.location === resourceField.location
      );
      if (matchingFields.length > 0) {
        const highestLevelField = matchingFields.reduce((prev, current) =>
          prev.extra.next_level > current.extra.next_level ? prev : current
        );
        if (highestLevelField) {
          nextLevel = highestLevelField.extra.next_level;
        }
      }
      const nextResField =
        BUILDINGS_AND_PRICES[resourceField.type].levels[nextLevel];
      if (
        warehouse.wheat >= nextResField.wheat &&
        warehouse.wood >= nextResField.wood &&
        warehouse.stone >= nextResField.stone &&
        warehouse.iron >= nextResField.iron
      ) {
        setHasEnoughResources(true);
      } else {
        setHasEnoughResources(false);
      }
      setNextField({
        level: nextResField.level,
        wheat: nextResField.wheat,
        wood: nextResField.wood,
        stone: nextResField.stone,
        iron: nextResField.iron,
        type: resourceField.type,
      });
      if (cityBonus) {
        const bonus = cityBonus.building_speed_bonus / 100;
        const timeToBuild = Math.round(
          nextResField.seconds_to_build / (1 + bonus)
        );

        setActualBuildTime(timeToBuild);
      } else {
        setActualBuildTime(nextResField.seconds_to_build);
      }
    }
  }, [resourceField, warehouse, resourceTimers]);

  if (!resourceField) {
    return null;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative  top-[10vh] mx-4">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 text-black">
          <div className="bg-mainLightYellow p-4 overflow-y-scroll max-h-[67vh]">
            <div>
              <h2 className="text-2xl font-bold mb-4 mt-4 text-center">
                {resourceField.type} Level:{" "}
                {resourceField.status ? (
                  <div className="inline">{resourceField.status}</div>
                ) : (
                  <div className="inline">
                    {resourceField.level}{" "}
                    {isMaxLevel && <div className="inline">(MAX)</div>}
                  </div>
                )}
              </h2>

              {/*RES FIELD DESCRIPTION*/}
              <div className="grid grid-cols-1 xs:grid-cols-2 gap-4 place-items-center">
                <div className="order-2">
                  {resourceField.type === "Wheat" && (
                    <div>
                      <h4>{RESOURCE_FIELD_DESCRIPTIONS.Wheat}</h4>
                    </div>
                  )}
                  {resourceField.type === "Wood" && (
                    <div>
                      <h4>{RESOURCE_FIELD_DESCRIPTIONS.Wood}</h4>
                    </div>
                  )}
                  {resourceField.type === "Stone" && (
                    <div>
                      <h4>{RESOURCE_FIELD_DESCRIPTIONS.Stone}</h4>
                    </div>
                  )}
                  {resourceField.type === "Iron" && (
                    <div>
                      <h4>{RESOURCE_FIELD_DESCRIPTIONS.Iron}</h4>
                    </div>
                  )}
                </div>
                {/*RES FIELD IMAGE*/}
                {resourceField && (
                  <div className="lg:row-span-2 xl:row-span-2 xs:order-2 order-1">
                    <ResFieldInfoImage type={resourceField.type} />
                  </div>
                )}

                {/*RES FIELD NEXT LEVEL INFO*/}
                <div className="col-span-1 xs:col-span-2 md:col-span-2 lg:col-span-1 xl:col-span-1 order-3">
                  {nextLevelInfo && cityProductionStats && (
                    <ResFieldNextLevelInfo
                      nextLevelInfo={nextLevelInfo}
                      cityProductionStats={cityProductionStats}
                      resType={resourceField.type}
                      bonuses={cityBonus}
                    />
                  )}
                </div>
              </div>

              {/*NEXT LEVEL REQUIREMENTS*/}
              {!isMaxLevel && nextField && (
                <div className="justify-center">
                  <NextLevelRequirementsCard
                    level={nextField.level ? nextField.level : 1}
                    buildingName={nextField.type}
                    wheatReq={nextField.wheat}
                    woodReq={nextField.wood}
                    stoneReq={nextField.stone}
                    ironReq={nextField.iron}
                    /*                     cityProductionStats={cityProductionStats} */
                    build={build}
                    actualBuildTime={actualBuildTime}
                    hasEnoughResources={hasEnoughResources}
                    bonuses={cityBonus}
                  />
                </div>
              )}
            </div>
            {status && <h4 className="text-red-500">{status}</h4>}
          </div>
        </div>
      </div>
    </div>
  );
}

import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";
import {
  IoGameController,
  IoChatbubbles,
  IoPeople,
  IoMail,
} from "react-icons/io5";
import { CONSTANTS } from "../constants";
import { Link } from "react-router-dom";

export function AboutPage() {
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] flex flex-col ">
      <SignedOutsNavBar />
      <div className=" flex flex-col items-center justify-center mb-auto overflow-auto ">
        <div className="flex flex-col  h-full  p-4 md:p-8 w-full max-w-6xl">
          <h1 className="text-4xl md:text-5xl text-center font-bold mb-8 text-mainDarkRed">
            About the Game
          </h1>

          <div className="grid md:grid-cols-2 gap-6 mx-4 md:mx-8 text-lg">
            <div className="flex items-center bg-mainDarkRed p-6 rounded-lg text-white shadow-md">
              <IoGameController className="text-3xl mr-4" />
              <p>
                This game started out as a challenge to my technical skills, but
                evolved into a passion project ive been working on the last 2
                years.
              </p>
            </div>
            <div className="flex items-center bg-mainDarkRed p-6 rounded-lg text-white shadow-md">
              <IoChatbubbles className="text-3xl mr-4" />
              <p>
                Join my{" "}
                <a target="_blank" href="https://discord.gg/HbtAvbdkJ7">
                  Discord server
                </a>{" "}
                to discuss strategies, find teammates, and become part of my
                community.
              </p>
            </div>
            <div className="flex items-center bg-mainDarkRed p-6 rounded-lg text-white shadow-md">
              <IoPeople className="text-3xl mr-4" />
              <p>
                The goal has been to make a game inspired by the browser games i
                used to play in my childhood. The time is sat in the roman
                empire, where you get the chance to build your kingdom through
                attacking players, growing your farm or becoming a fearless
                gambler.
              </p>
            </div>
            <div className="flex items-center bg-mainDarkRed p-6 rounded-lg text-white shadow-md">
              <IoMail className="text-3xl mr-4" />
              <p>
                For inquiries and support,{" "}
                <Link to={`mailto:${CONSTANTS.SUPPORT_EMAIL}`}>contact me</Link>{" "}
                at {CONSTANTS.SUPPORT_EMAIL} or report any bugs found{" "}
                <Link to={`${import.meta.env.VITE_FRONTEND}/bugReport`}>
                  Here
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <SignedOutFooter />
    </div>
  );
}

export function CatapultTargetList({ setCatapultTarget }) {
  const buildingList = [
    "Wheat",
    "Wood",
    "Stone",
    "Iron",
    "Barracks",
    "Mapchamber",
    "Marketplace",
    "Warehouse",
    "Artifex Foundry",
    "Embassy",
    "Mill",
    "Lumberyard",
    "Quarry",
    "Smith",
    "Brothel",
    "Casino",
    "Church",
  ];

  function handleSelectChange(event) {
    setCatapultTarget(event.target.value);
  }
  return (
    <div>
      <label htmlFor="catapult-target">Catapult Target: </label>
      <select id="catapult-target" onChange={handleSelectChange}>
        <option value="" disabled selected>
          Select a target
        </option>
        {buildingList.map((building) => (
          <option key={building} value={building}>
            {building}
          </option>
        ))}
      </select>
    </div>
  );
}

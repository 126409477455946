import { useContext, useEffect, useState } from "react";
import { CasinoContext, CityConfigContext } from "../../index";
import { SkillTreeProgressBar } from "./SkillTreeProgressBar";
import { CasinoCoinResponsive } from "../../images/CasinoCoinResponsive";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function SkillTree({ tree_type, progress }) {
  const { inCityId } = useContext(CityConfigContext);
  const { currentCasinoCoins } = useContext(CasinoContext);
  const [treeStatus, setTreeStatus] = useState();
  const [tree, setTree] = useState([]);
  const [purchaseStatus, setPurchaseStatus] = useState({
    skillId: null,
    statusMessage: null,
  });
  const [resetStatus, setResetStatus] = useState();
  const [resetSkillModalOpen, setResetSkillModalOpen] = useState(false);

  async function getSkillTree() {
    setTreeStatus(undefined);
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/skill/tree/${tree_type}/${inCityId}`,
      {
        credentials: "include",
      }
    );

    if (!res.ok) {
      const err = await res.text();
      setTreeStatus(err);
    } else {
      const data = await res.json();
      setTree(data);
    }
  }

  function openResetModal() {
    setResetSkillModalOpen(true);
    setResetStatus(undefined);
  }

  async function confirmReset(e) {
    e.preventDefault();
    setResetStatus(undefined);

    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/skill/reset/${tree_type}/${inCityId}`,
      {
        credentials: "include",
      }
    );

    if (!res.ok) {
      const err = await res.text();
      setResetStatus(err);
    } else {
      setResetSkillModalOpen(false);
      const data = await res.json();

      setTree(data);
    }
  }

  function cancelReset(e) {
    e.preventDefault();
    setResetSkillModalOpen(false);
    setResetStatus(undefined);
  }

  useEffect(() => {
    if (tree_type) {
      getSkillTree();
    }
  }, [tree_type]);

  if (treeStatus) {
    return <div>Error: {treeStatus}</div>;
  }

  return (
    <div className="p-8 bg-gray-900 text-white">
      <div className="flex justify-between">
        <div className="flex place-items-center">
          <button onClick={openResetModal} className="bg-transparent">
            <FontAwesomeIcon icon={faDice} className="hover:scale-110" />
          </button>
        </div>
        {resetStatus && <h4 className="text-red-500">{resetStatus}</h4>}
        <div>
          <div>
            {progress && progress.available_points}/{progress.level} points
            available.
          </div>
          <div className="flex justify-end">
            <p className="mr-2">{currentCasinoCoins}</p>
            <CasinoCoinResponsive />
          </div>
        </div>
      </div>
      <h4 className="text-2xl font-bold mb-4">Skills</h4>
      {progress && (
        <SkillTreeProgressBar
          level={progress.level}
          xp={progress.xp}
          next_goal={progress.next_goal}
        />
      )}

      {tree.length > 0 ? (
        tree.map((rootSkill) => (
          <SkillTreeNode
            key={rootSkill.id}
            skill={rootSkill}
            setTree={setTree}
            purchaseStatus={purchaseStatus}
            setPurchaseStatus={setPurchaseStatus}
          />
        ))
      ) : (
        <div>Loading...</div>
      )}

      {resetSkillModalOpen && (
        <div
          className="z-[10000] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={cancelReset}
        >
          <div className="bg-gray-600 p-4 rounded-lg shadow-lg ">
            <h5 className="text-lg font-bold">Confirm reset</h5>
            <p className="text-sm">
              Are you sure you want to reset this skilltree? That will cost 500
              gold coins. You have {currentCasinoCoins}.{" "}
            </p>
            <p className="text-sm">
              This will return all skill points used. But also remove your
              buildings in the new building spots, and remove all gained bonuses
              from this tree.
            </p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={cancelReset}
                className="bg-gray-400 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={confirmReset}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SkillNode({ skill, setTree, purchaseStatus, setPurchaseStatus }) {
  const { inCityId } = useContext(CityConfigContext);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  function openInfo() {
    setIsInfoModalOpen(true);
  }

  function closeInfo(e) {
    e.preventDefault();
    setIsInfoModalOpen(false);
  }

  function handleUpgradeClick() {
    setIsUpgradeModalOpen(true);
  }

  function confirmUpgrade() {
    setIsUpgradeModalOpen(false);
    upgradeSkill(skill);
  }

  function cancelUpgrade(e) {
    e.preventDefault();
    setIsUpgradeModalOpen(false);
  }

  async function upgradeSkill(skill) {
    setPurchaseStatus({ skillId: null, statusMessage: null });
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/skill/purchase/${
        skill.id
      }/${inCityId}`,
      {
        credentials: "include",
      }
    );

    if (!res.ok) {
      const err = await res.text();
      setPurchaseStatus({ skillId: skill.id, statusMessage: err });
    } else {
      const data = await res.json();
      setTree(data);
    }
  }

  return (
    <div className="relative flex flex-col items-center p-4 bg-gray-800 text-white rounded-lg shadow-lg w-44">
      <h4 className="text-base font-bold mt-2">{skill.skill_name}</h4>
      <div className="mt-2 text-yellow-400">
        Rank: {skill.current_rank}/{skill.max_rank}
      </div>
      <div className="flex space-x-1">
        <button
          onClick={handleUpgradeClick}
          className={`p-2 ${
            skill.current_rank === skill.max_rank
              ? "bg-gray-500 cursor-not-allowed"
              : "bg-blue-500 hover:bg-blue-600"
          }`}
          disabled={skill.current_rank === skill.max_rank}
        >
          +
        </button>
      </div>
      <p
        className="absolute top-0 right-0 mt-1 mr-1 bg-blue-500 text-white font-bold rounded-full w-6 h-6 flex items-center justify-center cursor-pointer hover:bg-blue-600"
        onClick={openInfo}
      >
        i
      </p>

      {purchaseStatus.skillId === skill.id && (
        <h4 className="text-red-500">{purchaseStatus.statusMessage}</h4>
      )}

      {isUpgradeModalOpen && (
        <div
          className="z-[10000] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={cancelUpgrade}
        >
          <div className="bg-gray-600 p-4 rounded-lg shadow-lg">
            <h5 className="text-lg font-bold">Confirm Upgrade</h5>
            <p className="text-sm">
              Are you sure you want to upgrade this skill?
            </p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={cancelUpgrade}
                className="bg-gray-400 text-white px-4 py-2 rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={confirmUpgrade}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {isInfoModalOpen && (
        <div
          className="z-[10000] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={closeInfo}
        >
          <div className="bg-gray-600 p-4 rounded-lg shadow-lg">
            <h4>{skill.skill_name}</h4>
            <p>{skill.description}</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={closeInfo}
                className="bg-gray-400 text-white px-4 py-2 rounded-lg"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SkillTreeNode({ skill, setTree, purchaseStatus, setPurchaseStatus }) {
  return (
    <div className="flex flex-col items-center">
      <SkillNode
        skill={skill}
        setTree={setTree}
        purchaseStatus={purchaseStatus}
        setPurchaseStatus={setPurchaseStatus}
      />
      {skill.children && skill.children.length > 0 && (
        <div className="mt-4 flex space-x-8">
          {skill.children.map((child) => (
            <SkillTreeNode
              key={child.id}
              skill={child}
              setTree={setTree}
              purchaseStatus={purchaseStatus}
              setPurchaseStatus={setPurchaseStatus}
            />
          ))}
        </div>
      )}
    </div>
  );
}

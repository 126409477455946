import { useContext } from "react";
import { CityConfigContext } from "../../..";
import { InventorySpotCard } from "./InventorySpotCard";
import { AnimatePresence } from "framer-motion";
import { LootChestPrizeModal } from "../../modals/LootChestPrizeModal";

export function ChestInventoryDetails({
  updateInventory,
  unclaimedPrize,
  setNewUnclaimedPrize,
  items,
  claimPrize,
}) {
  const { cityBonus } = useContext(CityConfigContext);

  return (
    <div>
      <div>
        <h4>Loot chest drop chance: {cityBonus.casino_chest_drop_chance}</h4>
        <h4>
          Loot chest upgrade chance: {cityBonus.casino_chest_upgrade_chance}
        </h4>
      </div>

      <div className="grid grid-cols-2  xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 lg:grid-cols-6 xl:grid-cols-7 gap-1 ">
        {items.map((item, index) => (
          <InventorySpotCard
            index={index}
            item={item}
            setNewItems={updateInventory}
            setUnclaimedPrize={setNewUnclaimedPrize}
            key={index}
          />
        ))}
        <AnimatePresence>
          {unclaimedPrize && (
            <LootChestPrizeModal
              prize={unclaimedPrize}
              closeModal={claimPrize}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../index";
import { CityTokensComponent } from "../Components/CityTokensComponent";

export function CityStatsPage() {
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[12vh] bg-mainDarkBrown p-2 md:w-[50vw] mx-auto p-2 rounded">
        <div className="">
          {/* CITY BOOSTS */}

          {/* CITY TOKENS */}
          <div className=" p-4">
            <div className="bg-mainLightBrown1 rounded">
              <div>
                <h4 className="text-center text-2xl">Tokens</h4>
                <CityTokensComponent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import PuzzlePieceAImage from "../../../images/puzzlePieces/puzzlePieceA.png";
import PuzzlePieceBImage from "../../../images/puzzlePieces/puzzlePieceB.png";
import PuzzlePieceCImage from "../../../images/puzzlePieces/puzzlePieceC.png";

export function PuzzlePieceInventory({ puzzle }) {
  return (
    <div className="max-w-4xl mx-auto p-2 md:p-6 bg-gray-800 rounded-lg shadow-lg mb-6">
      <h4 className="text-2xl font-bold text-yellow-400 mb-4 text-center">
        Your Puzzle Pieces
      </h4>

      <div className="grid grid-cols-3 gap-1">
        {puzzle.pieceACount > 0 && (
          <div className="bg-gray-700 p-4 rounded-lg text-center hover:bg-gray-600 transition">
            <img
              src={PuzzlePieceAImage}
              alt="Puzzle piece A image"
              className="w-24 mx-auto mb-2"
            />
            <h4 className="text-xl font-bold text-green-400">
              {puzzle.pieceACount} x Piece A
            </h4>
          </div>
        )}
        {puzzle.pieceBCount > 0 && (
          <div className="bg-gray-700 p-4 rounded-lg text-center hover:bg-gray-600 transition">
            <img
              src={PuzzlePieceBImage}
              alt="Puzzle piece B image"
              className="w-24 mx-auto mb-2"
            />
            <h4 className="text-xl font-bold text-green-400">
              {puzzle.pieceBCount} x Piece B
            </h4>
          </div>
        )}
        {puzzle.pieceCCount > 0 && (
          <div className="bg-gray-700 p-4 rounded-lg text-center hover:bg-gray-600 transition">
            <img
              src={PuzzlePieceCImage}
              alt="Puzzle piece C image"
              className="w-24 mx-auto mb-2"
            />
            <h4 className="text-xl font-bold text-green-400">
              {puzzle.pieceCCount} x Piece C
            </h4>
          </div>
        )}
      </div>
    </div>
  );
}

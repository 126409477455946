import { useContext } from "react";
import { ResourceField } from "../Components/resource/ResourceField.jsx";

import "../assets/main.css";
import { CityConfigContext } from "../index.jsx";

export function ResourcePage() {
  const { inOccupiedCity } = useContext(CityConfigContext);
  useContext;
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      {inOccupiedCity ? (
        <h4>Occuupation in process</h4>
      ) : (
        <div className="relative  top-[10vh]">
          <ResourceField />
        </div>
      )}
    </div>
  );
}

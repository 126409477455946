import { useContext, useState, useEffect } from "react";
import { CityConfigContext } from "../../index";
import { CityBoostsComponent } from "../CityBoostsComponent";

export function ActiveAbilitiesBar() {
  const { cityBonus, setCityBonus, cityTokenBoosts } =
    useContext(CityConfigContext);

  if (
    !cityTokenBoosts &&
    (!cityBonus || cityBonus.active_abilities.length <= 0)
  ) {
    return undefined;
  }

  return (
    <div className=" mb-2">
      <CityBoostsComponent />
    </div>
  );
}

import { React, useContext, useEffect, useState } from "react";
import { PaginationControls } from "../PaginationControls";
import { CityConfigContext } from "../..";

export function MarketplaceHistoryCard() {
  const { inCityId } = useContext(CityConfigContext);
  const [historyData, setHistoryData] = useState([]);
  const [status, setStatus] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);

  const getDataCount = async () => {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/marketplace/count`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ cityId: inCityId }),
        credentials: "include",
      }
    );

    const data = await res.json();

    setTotalPageCount(data);
  };

  // get latest 10 entries of sendt and recieved resources
  async function getMarketplaceHistory() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/marketplace/history/${pageNumber}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ cityId: inCityId }),
        credentials: "include",
      }
    );

    setStatus("");
    if (res.status === 200) {
      const data = await res.json();

      setHistoryData(data);
    }
    if (res.status === 201) {
      setStatus("You have no history yet");
    }
  }
  useEffect(() => {
    getMarketplaceHistory();
  }, [pageNumber]);

  useEffect(() => {
    getDataCount();
    getMarketplaceHistory();
  }, []);

  return (
    <div className="flex flex-col items-center bg-mainLightBrown3 text-white p-4 rounded-lg shadow-lg">
      <h2 className="xxs:text-base xs:text-xl sm:text-2xl md:text-3xl font-bold mb-4">
        Marketplace history
      </h2>
      <PaginationControls
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPageCount={totalPageCount}
      />
      {status && <div className="text-red-500">{status}</div>}
      <ul className="w-full">
        {historyData.map((data) => (
          <li className="flex justify-between bg-mainLightBrown1 px-4 py-2 mb-2 rounded-md">
            <span className="text-xs sm:text-sm lg:text-base xl:text-lg font-semibold">
              {data.event}
            </span>
            <div className="flex place-items-center">
              <span className="text-xs sm:text-sm lg:text-base xl:text-lg text-gray-800">
                {new Date(data.time).toLocaleString()}
              </span>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";

import { TabItem } from "./Marketplace";
import { BuildingDescAndImage } from "../cards/BuildingDescAndImage";
import { MapchamberProduction } from "./MapchamberProduction";
import { CityConfigContext } from "../../index";
import { MapchamberManagement } from "./MapchamberManagement";

export function MapChamber({ level, hideUpgradeMenu }) {
  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (tab) => {
    if (tab === "overview") {
      hideUpgradeMenu(true);
    } else {
      hideUpgradeMenu(false);
    }
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="mb-4">
        <ul className="flex border-b">
          <TabItem
            label="Overview"
            active={activeTab === "overview"}
            onClick={() => handleTabChange("overview")}
          />
          <TabItem
            label="Production"
            active={activeTab === "production"}
            onClick={() => handleTabChange("production")}
          />
          <TabItem
            label="City management"
            active={activeTab === "management"}
            onClick={() => handleTabChange("management")}
          />
        </ul>
      </div>

      {activeTab === "overview" && (
        <BuildingDescAndImage type="Mapchamber" isDetails={true} />
      )}
      {activeTab === "production" && <MapchamberProduction level={level} />}
      {activeTab === "management" && <MapchamberManagement />}
    </div>
  );
}

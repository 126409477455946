import { HighScoreAllPlayersList } from "../Components/lists/HighScoreAllPlayersList.jsx";
import { React, useState } from "react";
import { AllTopListsPlayers } from "../Components/lists/AllTopListsPlayers";
import { HighScoreAllAlliancesList } from "../Components/lists/HighScoreAllAlliancesList";
import { HighScoreNavDropDown } from "../Components/lists/HighScoreNavDropDown";
import { PaginationControls } from "../Components/PaginationControls";

export function HighScoresPage() {
  const [showOption, setShowOption] = useState("allPlayers");
  function changeView(option) {
    if (option !== showOption) {
      setPageNumber(1);
      setTotalPageCount(0);
      setShowOption(option);
    }
  }

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(0);
  const options = [
    { value: "allPlayers", text: "All players" },
    { value: "allAlliances", text: "All alliances" },
    { value: "topPlayers", text: "Players top 10" },
    { value: "topAlliances", text: "Alliances top 10" },
    { value: "topCasino", text: "Casino top 10" },
  ];

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[10vh]">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 m-2">
          <div className="max-h-[70vh] overflow-y-auto bg-mainLightYellow p-4">
            <div className="flex flex-col items-center">
              <div>
                <ul className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base  text-white pb-4">
                  <li className="mr-2">
                    <button
                      className="bg-mainDarkBrown rounded-none"
                      onClick={() => changeView("allPlayers")}
                    >
                      All players
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className="bg-mainDarkBrown rounded-none"
                      onClick={() => changeView("allAlliances")}
                    >
                      All alliances
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className="bg-mainDarkBrown rounded-none"
                      onClick={() => changeView("topPlayers")}
                    >
                      Players top 10
                    </button>
                  </li>
                  <li className="mr-2">
                    <button
                      className="bg-mainDarkBrown rounded-none"
                      onClick={() => changeView("topAlliances")}
                    >
                      Alliances top 10
                    </button>
                  </li>
                  <li>
                    <button
                      className="bg-mainDarkBrown rounded-none"
                      onClick={() => changeView("topCasino")}
                    >
                      Casino top 10
                    </button>
                  </li>
                </ul>
                <HighScoreNavDropDown
                  options={options}
                  onOptionChange={(value) => changeView(value)}
                  showOption={showOption}
                />
              </div>
              <div className="h-full mt-2 grid  max-h-[52vh]  overflow-y-auto mb-2">
                {showOption === "allPlayers" ? (
                  <HighScoreAllPlayersList
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    totalPageCount={totalPageCount}
                    setTotalPageCount={setTotalPageCount}
                  />
                ) : showOption === "topPlayers" ? (
                  <AllTopListsPlayers type={"player"} />
                ) : showOption === "allAlliances" ? (
                  <HighScoreAllAlliancesList
                    setPageNumber={setPageNumber}
                    pageNumber={pageNumber}
                    totalPageCount={totalPageCount}
                    setTotalPageCount={setTotalPageCount}
                  />
                ) : showOption === "topAlliances" ? (
                  <AllTopListsPlayers type={"alliances"} />
                ) : showOption === "topCasino" ? (
                  <AllTopListsPlayers type={"casino"} />
                ) : (
                  <></>
                )}
              </div>
              {showOption === "allPlayers" || showOption === "allAlliances" ? (
                <div>
                  <PaginationControls
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    totalPageCount={totalPageCount}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

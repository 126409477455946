import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../../index";

import { PuzzlePieceInventory } from "./PuzzlePieceInventory";
import { PuzzleStoreOverview } from "./PuzzleStoreOverview";

export function CasinoPuzzleComponent() {
  const { inCityId } = useContext(CityConfigContext);

  const [puzzle, setPuzzle] = useState();

  async function getCityPuzzle() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/puzzle/${inCityId}`,
      { credentials: "include" }
    );

    if (res.ok) {
      const data = await res.json();
      setPuzzle(data);
    }
  }

  useEffect(() => {
    getCityPuzzle();
  }, []);

  if (!puzzle) {
    return <h4 className="text-center text-yellow-400">Loading...</h4>;
  }

  return (
    <div className="grid place-items-center gap-6 bg-gray-900 p-2 md:p-8 rounded-lg shadow-lg">
      <PuzzlePieceInventory puzzle={puzzle} />
      <PuzzleStoreOverview puzzle={puzzle} setPuzzle={setPuzzle} />
    </div>
  );
}

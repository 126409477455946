import React, { useContext, useState } from "react";
import { CityConfigContext } from "../..";

export function NewMessageCard(props) {
  const { inCityId } = useContext(CityConfigContext);
  const initialRecipient = props.routeState?.recipientsUsername || "";
  const initialSubject = props.routeState?.subject || "";

  const [username, setUsername] = useState(initialRecipient);
  const [subject, setSubject] = useState(initialSubject);
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/messages/send`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          username: username,
          subject: subject,
          message: message,
          cityId: inCityId,
        }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setUsername("");
      setSubject("");
      setMessage("");
      props.naver("sendt");
    }

    if (res.status === 204) {
      setStatusMessage("You cant send messages to yourself!");
    }

    if (res.status === 404) {
      setStatusMessage("Username does not exist");
    }
    if (res.status === 400) {
      setStatusMessage("Subject is too long! Max 30 characters");
    }
  };

  return (
    <div className="rounded-lg w-full mx-auto">
      <form onSubmit={handleSubmit}>
        <div className="flex justify-center items-center mb-6">
          <div className="w-[50%]">
            <label
              htmlFor="username"
              className="block text-lg text-gray-600 mb-2 text-center font-semibold"
            >
              To
            </label>
            <input
              type="text"
              id="username"
              name="username"
              value={username}
              onChange={handleUsernameChange}
              className="mt-1 block bg-mainLightBrown3 w-full border border-gray-300 rounded-md shadow-sm p-3 focus:outline-none focus:border-indigo-300 focus:ring-indigo-200 text-xs sm:text-sm md:text-base"
              placeholder="Enter a username"
              required
            />
          </div>
        </div>

        <label
          htmlFor="subject"
          className="block text-lg text-gray-600 font-semibold mb-2 text-center "
        >
          Subject
        </label>
        <input
          type="text"
          id="subject"
          name="subject"
          value={subject}
          onChange={handleSubjectChange}
          className="mt-1 block w-full border border-gray-300 bg-mainLightBrown3 rounded-md shadow-sm p-3 focus:outline-none focus:border-indigo-300 focus:ring-indigo-200 text-xs sm:text-sm md:text-base"
          placeholder="Enter a subject"
          required
          maxLength={30}
        />

        <div className="mb-6">
          <label
            htmlFor="message"
            className="block text-lg font-semibold text-gray-600 mb-2 text-center"
          >
            Message
          </label>
          <textarea
            id="message"
            name="message"
            value={message}
            onChange={handleMessageChange}
            className="mt-1 block w-full border border-gray-300 bg-mainLightBrown3 rounded-md shadow-sm p-3 focus:outline-none focus:border-indigo-300 focus:ring-indigo-200 text-xs sm:text-sm md:text-base"
            rows="6"
            placeholder="Type your message here"
            required
            maxLength={500}
          >
            {" "}
          </textarea>
        </div>
        <div className="flex justify-center items-center mb-6">
          <div className="w-[30%]">
            <button
              type="submit"
              className="w-full bg-mainDarkBrown text-white font-semibold py-3 rounded-md  focus:outline-none focus:ring-4  focus:ring-opacity-50 text-xs sm:text-sm md:text-base"
            >
              Send
            </button>
          </div>
        </div>
        {statusMessage && <div className="text-red-500">{statusMessage}</div>}
      </form>
    </div>
  );
}

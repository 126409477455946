import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "..";
import { HelperFunctions } from "../HelperFunctions";

import { BuffItem } from "./BuffItem";
import DoubleRewardsAbilityIcon from "../images/skills/DoubleRewardsSkill.png";
import TwoxCoinsSkill from "../images/skills/2xCoinsSkill.png";
import OffensiveSkill from "../images/skills/OffensiveSkillIcon.png";
import DefensiveSkill from "../images/skills/DefensiveSkillIcon.png";
import ResourceSkill from "../images/skills/ResourceSkill.png";
import ExtraQueueItemIcon from "../images/tokens/ExtraQueueItemToken.png";
import TroopTrainingIcon from "../images/tokens/TroopsTrainingToken.png";
import ResourceBoostIcon from "../images/tokens/ResourceBoostToken.png";
import { Tooltip } from "react-tooltip";

export function CityBoostsComponent() {
  const { cityTokenBoosts, cityBonus, inCityId } =
    useContext(CityConfigContext);
  const [buffs, setBuffs] = useState([]);
  const abilityIcons = {
    "Double rewards": DoubleRewardsAbilityIcon,
    "2x Coins": TwoxCoinsSkill,
    "Offensive Skill": OffensiveSkill,
    "Defensive Skill": DefensiveSkill,
    "Resource ability I": ResourceSkill,
  };
  const toolTips = {
    "Double rewards": "Casino rewards are doubled.",
    "2x Coins": "Coins are selling for twice as much resources.",
    "Offensive Skill": "Increases the attack damage of your army.",
    "Defensive Skill": "Increases the defence power of your army.",
    "Resource ability I": "Doubles the resources generated.",
    "Resource Boost": "40% more resources generated.",
    "Troop Training Boost": "Trains troops 60% faster.",
    "Extra Queue Boost": "Can add an extra building to building queue.",
  };
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };
  useEffect(() => {
    if (cityTokenBoosts || cityBonus) {
      const combinedBuffs = [];

      // Add City Token Boosts
      if (cityTokenBoosts) {
        combinedBuffs.push(
          {
            name: "Resource Boost",
            icon: ResourceBoostIcon,
            cooldown: HelperFunctions.calculate24HourCooldown(
              cityTokenBoosts.resource_boost_last_used
            ),
          },
          {
            name: "Troop Training Boost",
            icon: TroopTrainingIcon,
            cooldown: HelperFunctions.calculate24HourCooldown(
              cityTokenBoosts.troop_training_boost_last_used
            ),
          },
          {
            name: "Extra Queue Boost",
            icon: ExtraQueueItemIcon,
            cooldown: HelperFunctions.calculate24HourCooldown(
              cityTokenBoosts.extra_queue_boost_last_used
            ),
          }
        );
      }

      // Add Active Abilities
      if (cityBonus && cityBonus.active_abilities) {
        cityBonus.active_abilities.forEach((ability) => {
          const now = new Date().getTime();
          const activeUntil = new Date(ability.activeUntil).getTime();
          const remainingTime = Math.max(0, activeUntil - now);

          // Only add abilities that have remaining time
          if (remainingTime > 0) {
            combinedBuffs.push({
              name: ability.name,
              icon: abilityIcons[ability.name],
              cooldown: remainingTime,
            });
          }
        });
      }

      // Filter out buffs that have no cooldown (i.e., inactive buffs)
      const activeBuffs = combinedBuffs.filter((buff) => buff.cooldown > 0);

      setBuffs(activeBuffs);
    }
  }, [cityTokenBoosts, cityBonus]);

  useEffect(() => {
    const interval = setInterval(() => {
      setBuffs((prevBuffs) =>
        prevBuffs.map((buff) => {
          const newCooldown = Math.max(0, buff.cooldown - 1000);

          // Check if the cooldown reaches 0 and trigger your action here
          if (newCooldown === 0 && buff.cooldown > 0) {
            const callBackend = async () => {
              await fetch(
                `${
                  import.meta.env.VITE_GAMEHOST
                }/api/resource/bonus/refresh/${inCityId}`,
                {
                  credentials: "include",
                }
              );
            };
            callBackend();
          }

          return {
            ...buff,
            cooldown: newCooldown,
          };
        })
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  if (buffs.length === 0) {
    return null; // No buffs to display
  }

  return (
    <div>
      {/* Button to toggle the visibility */}

      <div className="grid place-items-center bg-mainDarkBrown">
        <h4 className="text-sm font-bold">Active buffs</h4>
        <div className="animate-bounce">
          <svg
            onClick={() => toggleVisibility()}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300  ${
              isVisible ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>

      {/* Conditionally render the list of buffs */}
      {isVisible && (
        <div className="bg-mainDarkBrown p-2">
          <ul className="grid grid-cols-2 gap-1 max-h-[15vh] overflow-auto bg-mainLightBrown1">
            {buffs.map(
              (buff) =>
                buff.cooldown > 0 && (
                  <a
                    className="my-anchor-element"
                    data-tooltip-content={toolTips[buff.name]}
                  >
                    <BuffItem
                      key={buff.name}
                      name={buff.name}
                      icon={buff.icon}
                      cooldown={buff.cooldown}
                    />
                  </a>
                )
            )}
          </ul>
        </div>
      )}
      <Tooltip anchorSelect=".my-anchor-element"></Tooltip>
    </div>
  );
}

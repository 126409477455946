import { CatapultResponsive } from "../../images/troops/CatapultResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { useFoundryTimerState } from "../contexts/timerContext";

export function NextFoundryItemInQueueModal() {
  const foundryTimers = useFoundryTimerState();

  if (foundryTimers === undefined || foundryTimers.length <= 0) {
    return;
  }

  return (
    <div className="bg-gray-800 bg-opacity-50 rounded-lg shadow-md p-4 text-white hover:shadow-lg transition-all duration-200 ease-in-out flex text-center justify-center">
      <h3 className="text-xs lg:text-sm font-fantasy font-semibold mb-2">
        Next:
      </h3>
      <div className="font-pixel flex">
        {foundryTimers[0].troopType === "Catapult" ? (
          <CatapultResponsive />
        ) : (
          <></>
        )}{" "}
        <h4>{foundryTimers[0].timeLeftNextUnit}</h4>
      </div>
    </div>
  );
}

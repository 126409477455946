import { useEffect, useState } from "react";

export function AttackOptions({
  setSelectedAttackOption,
  setScoutingSelected,
  raiderTokenAmount,
}) {
  const [arrowSymbol, setArrowSymbol] = useState(0x25bc);
  const options = [
    { value: "normal", text: "Normal" },
    { value: "scout", text: "Scouting" },
    { value: "raider", text: "Raider Token", disabled: raiderTokenAmount <= 0 },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("normal");

  const handleOptionSelect = (value) => {
    if (value === "scout") {
      setScoutingSelected(true);
    } else {
      setScoutingSelected(false);
    }
    setSelectedAttackOption(value);
    setSelectedOption(value);
    setIsOpen(false);
  };
  useEffect(() => {
    if (isOpen === true) {
      setArrowSymbol(0x25b2);
    } else {
      setArrowSymbol(0x25bc);
    }
  }, [isOpen]);
  return (
    <div className="relative inline-block text-left">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="px-4 py-2 text-sm font-medium text-white bg-[#005f6b] rounded-md hover:bg-blue-600 focus:outline-none"
      >
        {options.find((option) => option.value === selectedOption)?.text ||
          "Select Attack Option"}
        {String.fromCharCode(arrowSymbol)}
      </button>
      {isOpen && (
        <ul className="absolute left-0 z-10 mt-2 w-48 bg-[#005f6b] rounded-md shadow-lg border border-gray-700">
          {options.map((option) => (
            <li key={option.value} className="hover:bg-blue-500">
              <button
                onClick={() => handleOptionSelect(option.value)}
                className={`block px-4 py-2 text-sm capitalize w-full text-left text-white  ${
                  option.disabled
                    ? "opacity-50 cursor-not-allowed"
                    : "bg-[#005f6b]"
                }`}
                disabled={option.disabled}
              >
                {option.text}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

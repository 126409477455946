import { useContext, useEffect, useState } from "react";
import { TabItem } from "./Marketplace";
import { BuildingDescAndImage } from "../cards/BuildingDescAndImage";
import { FoundryTroopsList } from "./FoundryTroopsList";
import { CityConfigContext } from "../..";

export function Foundry({ level, hideUpgradeMenu }) {
  const { cityProductionStats, cityBonus } = useContext(CityConfigContext);
  const [activeTab, setActiveTab] = useState("overview");

  const [troopReq, setTroopReq] = useState();

  const handleTabChange = (tab) => {
    if (activeTab === "overview") {
      hideUpgradeMenu(false);
    } else {
      hideUpgradeMenu(true);
    }
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="mb-4">
        <ul className="flex border-b">
          <TabItem
            label="Overview"
            active={activeTab === "overview"}
            onClick={() => handleTabChange("overview")}
          />
          <TabItem
            label="Production"
            active={activeTab === "production"}
            onClick={() => handleTabChange("production")}
          />
        </ul>
      </div>

      {activeTab === "overview" && (
        <BuildingDescAndImage type="Artifex Foundry" isDetails={true} />
      )}
      {activeTab === "production" && <FoundryTroopsList level={level} />}
    </div>
  );
}

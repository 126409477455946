import { HelperFunctions } from "../HelperFunctions";

export function BuffItem({ name, icon, cooldown }) {
  return (
    <li className=" m-1 rounded-lg p-1 text-center  transform hover:scale-110 transition-all duration-300 ease-in-out hover:rotate-2 cursor-pointer relative">
      <div className="grid grid-cols-1 place-items-center text-white text-xxs lg:text-xxxs xl:text-xxs">
        <div className="relative">
          <img
            src={icon}
            alt={`${name} ability image`}
            className="w-[30px] mb-2 hover:animate-spin"
          />
          {cooldown <= 0 && (
            <div className="absolute inset-0 bg-red-500 bg-opacity-50 rounded-full flex items-center justify-center text-white font-bold text-lg">
              X
            </div>
          )}
        </div>
        <span className="text-gray-100 font-extrabold tracking-wider drop-shadow-md">
          {cooldown > 0
            ? HelperFunctions.secondsToTimestamp(Math.ceil(cooldown / 1000))
            : "Expired"}
        </span>
      </div>
      <div className="absolute inset-0 rounded-lg border-4 border-transparent hover:border-yellow-400 transition-colors duration-300 pointer-events-none"></div>
    </li>
  );
}

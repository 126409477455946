import { useContext, useEffect, useState } from "react";
import { CityConfigContext, WarehouseContext } from "../../index.jsx";

import { ResourceSpot } from "./ResourceSpot.jsx";

import { useTimerState } from "../contexts/timerContext";

export function ResourceField() {
  const { cityResourceFields } = useContext(CityConfigContext);
  const { warehouse } = useContext(WarehouseContext);
  const [newResField, setNewResField] = useState([]);

  const globalTimers = useTimerState();

  const [buildingTimers, setBuildingTimers] = useState(
    globalTimers.resourceTimers
  );

  useEffect(() => {
    setBuildingTimers(globalTimers.resourceTimers);
  }, [globalTimers]);

  function updateBuildingStatus() {
    if (buildingTimers.length > 0 && cityResourceFields) {
      const currentTime = new Date().getTime();
      cityResourceFields.forEach((buildingItem) => {
        let highestLevel = 0;
        buildingTimers.forEach((timerItem) => {
          if (timerItem.location === buildingItem.location) {
            if (timerItem.extra.next_level > highestLevel) {
              highestLevel = timerItem.extra.next_level;
            }

            if (
              timerItem.endTime > currentTime &&
              highestLevel !== buildingItem.level
            ) {
              buildingItem.status = `${buildingItem.level} -> ${highestLevel}`;
              buildingItem.nextLevel = highestLevel;
            }
          }
        });
      });
    }
  }

  useEffect(() => {
    if (cityResourceFields) {
      updateBuildingStatus();
    }
  }, [cityResourceFields, buildingTimers]);

  useEffect(() => {
    setNewResField(cityResourceFields);
  }, [cityResourceFields]);

  if (!newResField) {
    return <div>Loading..</div>;
  }
  return (
    <div className="mx-4 md:mx-0">
      <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
        <div className="grid grid-cols-2 xs:grid-cols-3  sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-8 max-w-full scrollbar scrollbar-thumb-mainLightGreen2 scrollbar-thick bg-mainLightYellow p-4  max-h-[74vh] min-h-0 overflow-auto">
          {newResField.map((field, index) => {
            if (index <= 5) {
              return (
                <ResourceSpot
                  type={"wheat"}
                  newField={field}
                  key={index}
                  className="sm:max-w-xs md:max-w-sm lg:max-w-md"
                  warehouse={warehouse}
                />
              );
            } else if (index <= 9) {
              return (
                <ResourceSpot
                  type={"wood"}
                  newField={field}
                  key={index}
                  className="sm:max-w-xs md:max-w-sm lg:max-w-md"
                  warehouse={warehouse}
                />
              );
            } else if (index <= 13) {
              return (
                <ResourceSpot
                  type={"stone"}
                  newField={field}
                  key={index}
                  className="sm:max-w-xs md:max-w-sm lg:max-w-md"
                  warehouse={warehouse}
                />
              );
            } else {
              return (
                <ResourceSpot
                  type={"iron"}
                  newField={field}
                  key={index}
                  className="sm:max-w-xs md:max-w-sm lg:max-w-md"
                  warehouse={warehouse}
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
}

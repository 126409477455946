import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants.js";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";

import { AuthContext } from "../index";

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState("");
  const { setAuthToken } = useContext(AuthContext);
  const navigate = useNavigate();
  async function logIn(e) {
    e.preventDefault();
    if (email.length <= 0 || password.length <= 0) {
      setStatus("Fill inn all fields");
      return;
    }
    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/auth/login`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ email, password }),
      credentials: "include",
    });
    const data = await res.text();
    if (res.status === 200) {
      //user logged in
      setStatus("Welcome!");
      setAuthToken(data);
      navigate("/resources");
    } else {
      // Not logged in
      setStatus(data);
    }
  }

  const resendVerificationEmail = async () => {
    setStatus(undefined);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/resend/verification`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: email }),
      }
    );
    if (res.ok) {
      setStatus("Resent verification email!");
    } else {
      const data = await res.text();
      setStatus(data);
    }
  };

  return (
    <div className="h-screen w-screen flex flex-col bg-gradient-to-b from-mainLightGreen to-[#d4a373]">
      <SignedOutsNavBar />
      <div
        className=" flex place-items-center justify-center h-screen text-black mb-auto "
        style={{ backgroundImage: "url('game_background.jpg')" }}
      >
        <div className="bg-mainDarkGreen bg-opacity-80 p-10 rounded-lg w-full sm:w-[500px] h-full sm:h-fit max-w-md ">
          <form onSubmit={logIn}>
            <h2 className="text-4xl font-bold mb-5">
              {CONSTANTS.GAME_NAME} Login
            </h2>
            <div className="mb-5">
              <label htmlFor="emailInput" className="block mb-2 text-2xl">
                Email
              </label>
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="email"
                onChange={(event) => setEmail(event.target.value)}
                id="emailInput"
                name="emailInput"
                required
              />
            </div>
            <div className="mb-5">
              <label htmlFor="passwordInput" className="block mb-2 text-2xl">
                Password
              </label>
              <input
                className="bg-gray-200 w-full p-2 mb-4 rounded-lg"
                type="password"
                id="passwordInput"
                name="passwordInput"
                onChange={(event) => setPassword(event.target.value)}
                required
              />
            </div>
            <div className="mb-5">
              <button className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-800">
                Login
              </button>
              {status && (
                <div>
                  <h4 className="mt-4 text-red-700">{status}</h4>
                  {status === "Email not verified" && (
                    <h4
                      onClick={resendVerificationEmail}
                      className="text-blue-500 hover:underline"
                    >
                      Resend Verification mail
                    </h4>
                  )}
                </div>
              )}
            </div>

            <div>
              <h4 className="mb-2 text-xl">Not signed up yet?</h4>
              <Link
                className="text-blue-600 hover:underline text-xl"
                to="/register"
              >
                Register
              </Link>
            </div>
          </form>
        </div>
      </div>
      <SignedOutFooter />
    </div>
  );
}

import { Link, useNavigate } from "react-router-dom";
import { CONSTANTS } from "../constants.js";
import CityImage1 from "../images/frontpageArtwork/cityOne.png";
import { SignedOutsNavBar } from "../Components/bars/SignedOutsNavBar";
import { SignedOutFooter } from "../Components/bars/SignedOutFooter";

export function MainGameInfoPage() {
  const navigate = useNavigate();

  return (
    <div className=" w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] flex flex-col">
      <SignedOutsNavBar />
      <div className="grid justify-center  mb-auto overflow-auto  ">
        <div className="flex-grow flex flex-col justify-center items-center space-y-10 py-10">
          <h1 className="text-6xl text-center font-bold text-gray-800">
            {CONSTANTS.GAME_NAME}
          </h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-6xl p-4">
            {/* Login Section */}
            <div
              onClick={() => navigate("/login")}
              className="flex flex-col items-center bg-mainAttack text-white p-6 rounded-lg shadow-lg transform hover:scale-[101%] transition-transform duration-300"
            >
              <h2 className="text-2xl font-semibold mb-6">
                Get Back Into the Action!
              </h2>
              <button
                onClick={() => navigate("/login")}
                className="bg-mainAttack2 hover:bg-mainAttack3 text-white font-bold py-3 px-6 rounded"
              >
                Login
              </button>
            </div>

            {/* Middle Image */}
            <div className="flex justify-center items-center">
              <img
                src={CityImage1}
                alt="City"
                className="max-h-80 w-auto rounded-lg shadow-lg"
              />
            </div>

            {/* News Section */}
            <div className="bg-gradient-to-br from-green-500 to-blue-600 p-6 rounded-lg shadow-lg transform hover:scale-[101%] transition-transform duration-300 text-white">
              <h4 className="text-2xl font-semibold mb-6">Latest News</h4>
              <ul>
                <li>
                  First round of {CONSTANTS.GAME_NAME} started{" "}
                  {CONSTANTS.LAST_ROUND_STARTED}. Report any bugs{" "}
                  <Link
                    to="/bugReport"
                    className="underline hover:text-blue-200"
                  >
                    here
                  </Link>
                  .
                </li>
              </ul>
            </div>

            {/* New Player Section */}
            <div
              onClick={() => navigate("/register")}
              className="md:col-span-3 flex justify-between items-center mt-8 bg-mainDefend text-white p-6 rounded-lg shadow-lg transform hover:scale-[101%] transition-transform duration-300"
            >
              <p className="font-semibold text-xl">New player?</p>
              <button className="bg-mainDefend2 hover:bg-mainDefend3 text-white font-bold py-3 px-6 rounded">
                Register
              </button>
            </div>
          </div>

          <div className="text-center mt-10 px-4">
            <h4 className="text-4xl font-semibold mb-4">
              Start Your New Adventure Today!
            </h4>
            <p className="text-lg">
              Build your empire and knock out other players to become the
              greatest leader of them all!
            </p>
          </div>
        </div>
      </div>

      <SignedOutFooter />
    </div>
  );
}

import { useEffect, useState, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaginationControls } from "../PaginationControls";

export function HighScoreAllPlayersList({ setTotalPageCount, pageNumber }) {
  const [playerPage, setPlayerPage] = useState([]);

  const navigate = useNavigate();

  const getDataCount = async () => {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/highscores/count/all/players`,
      {
        credentials: "include",
      }
    );
    const data = await res.json();

    setTotalPageCount(data);
  };

  async function getPage() {
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/highscores/all/players/${pageNumber}`,
      {
        credentials: "include",
      }
    );
    const data = await res.json();

    setPlayerPage(data);
  }

  useEffect(() => {
    const getData = async () => {
      await getPage();
    };
    if (playerPage.length === 0) {
      getDataCount();
      getData();
    }
  }, []);

  useEffect(() => {
    const getData = async () => {
      await getPage();
    };
    getData();
  }, [pageNumber]);

  if (playerPage.length <= 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-full overflow-x-auto text-lg sm:text-lg md:text-xl lg:text-xl xl:text-xl ">
      {/*MEDIUM AND BIG SCREEN*/}
      <div className="min-w-[40vw] hidden md:block">
        <div className="grid grid-cols-5 gap-4 bg-mainLightBrown1 text-center pt-2 pb-2 font-semibold ">
          <div>Rank</div>
          <div>Username</div>
          <div>Population</div>
          <div>Alliance</div>
          <div>Cities</div>
        </div>
        {playerPage.map((player, index) => (
          <div
            className={`grid grid-cols-5  ${
              index % 2 === 0 ? "bg-mainLightBrown3" : "bg-mainLightBrown1"
            } text-center pt-2 pb-2 text-sm md:text-sm lg:text-lg`}
            key={index}
          >
            <div>{player.rank}</div>
            <Link to={`/profile/${player.username}`}>{player.username}</Link>
            <div>{player.total_population}</div>
            {player.allianceTag ? (
              <Link to={`/alliance/${player.allianceTag}`}>
                #{player.allianceTag}
              </Link>
            ) : (
              <div>-</div>
            )}
            <div>{player.cityCount}</div>
          </div>
        ))}
      </div>

      {/*SMALL SCREENS*/}
      <div className="block md:hidden pt-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
          {playerPage.map((player, index) => (
            <div
              className={` ${
                index % 2 === 0 ? "bg-mainLightBrown3" : "bg-mainLightBrown1"
              } text-center p-2 text-xs xs:text-base`}
              key={index}
              onClick={() => navigate(`/profile/${player.username}`)}
            >
              <h4 className="font-semibold">Rank: {player.rank}</h4>
              <div className="flex justify-center">
                <h4>{player.username}</h4>
                {player.allianceTag && (
                  <h4
                    className="text-cyan-500 ml-2"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/alliance/${player.allianceTag}`);
                    }}
                  >
                    #{player.allianceTag}
                  </h4>
                )}
              </div>
              <div className="flex justify-center">
                <h4>Pop: {player.total_population}</h4>

                <h4 className="ml-2">Cities: {player.cityCount}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { CityConfigContext, UserContext } from "../..";
import { toast } from "react-toastify";
import { HelperFunctions } from "../../HelperFunctions";
export function MapchamberManagement() {
  const { capitalCity } = useContext(UserContext);
  const { inCityId } = useContext(CityConfigContext);
  const [changeCapitalCooldown, setChangeCapitalCooldown] = useState(0);
  const [canChangeCapital, setCanChangeCapital] = useState(false);

  async function changeCapital() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/city/capital`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ cityId: inCityId }),
        credentials: "include",
      }
    );
    if (res.status === 200) {
      setChangeCapitalCooldown(
        HelperFunctions.calculate1HourCooldown(new Date())
      );
      toast.success("Capital changed");
    } else {
      const data = await res.text();
      toast.error(data);
    }
  }

  useEffect(() => {
    setChangeCapitalCooldown(
      HelperFunctions.calculate1HourCooldown(capitalCity.last_changed_capital)
    );
  }, [capitalCity]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (changeCapitalCooldown > 0) {
        setChangeCapitalCooldown((prev) => prev - 1000);
      }
    }, 1000);
    if (changeCapitalCooldown <= 0) {
      setCanChangeCapital(true);
    } else {
      setCanChangeCapital(false);
    }

    return () => clearInterval(interval);
  }, [changeCapitalCooldown]);
  return (
    <div>
      <ul>Capital: {capitalCity.name}</ul>
      {capitalCity.capital_city_id !== inCityId && canChangeCapital && (
        <button onClick={changeCapital}>Make capital</button>
      )}
      {changeCapitalCooldown > 0 && (
        <p>
          Can change capital in{" "}
          {HelperFunctions.formatTime(changeCapitalCooldown)}
        </p>
      )}
    </div>
  );
}

import { useState, useEffect, useContext, React } from "react";
import { TopNavBar } from "../bars/TopNavBar";
import { LeftMenu } from "./LeftMenu";
import { CityInfoCard } from "../cards/CityInfoCard";
import { BuildingTimerList } from "../lists/BuildingTimerList";
import { AllTroopActionTimersList } from "../lists/AllTroopActionTimersList";
import { ResourceBar } from "../bars/ResourceBar";
import { useLocation } from "react-router-dom";
import { QuestGiverCard } from "../cards/QuestGiverCard";
import { CityConfigContext, UserContext } from "../../index";
import arrowImg from "../../images/NavBar/arrow.png";

export function AllFixed() {
  const { hasFinnishedTutorial } = useContext(UserContext);
  const { inOccupiedCity } = useContext(CityConfigContext);
  const location = useLocation();
  const [isCityInfoCardOpen, setCityInfoCardOpen] = useState(false);
  const [isLeftMenuOpen, setLeftMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  const shouldDisplayAllTroopActionTimersList =
    !location.pathname.endsWith("/cityActivity");

  const isOnLoginPage = location.pathname.endsWith("/login");
  const isOnRegisterPage = location.pathname.endsWith("/register");
  const isOnDiscordLogin = location.pathname.endsWith("/login-discord");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    // clean up the event listener when the component unmounts
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // close the city info card and left menu when screen width is greater than the breakpoint
    if (windowWidth >= 768) {
      setCityInfoCardOpen(false);
      setLeftMenuOpen(false);
    }
  }, [windowWidth]);

  if (isOnLoginPage || isOnRegisterPage || isOnDiscordLogin) {
    return null;
  }

  return (
    <div className="h-auto top-0 w-screen fixed z-50">
      <ResourceBar />
      <TopNavBar />

      {/*LEFT MENU*/}
      <div
        className={`fixed top-20 flex ${
          windowHeight < 755 && windowWidth > 450 && windowWidth < 766
            ? "flex-row"
            : "flex-col"
        } ${isLeftMenuOpen ? "left-8 top-24" : "-left-full md:left-0"} ${
          windowHeight > 865
            ? "max-h-[80vh] "
            : windowHeight > 800
            ? "max-h-[70vh]"
            : windowHeight > 700
            ? "max-h-[63vh]"
            : windowHeight > 660
            ? "max-h-[60vh]"
            : windowHeight > 560
            ? "max-h-[55vh]"
            : "max-h-[45vh] "
        }`}
      >
        {(windowWidth >= 768 || isLeftMenuOpen) &&
          shouldDisplayAllTroopActionTimersList && (
            <div className="relative mb-4 ml-2">
              <AllTroopActionTimersList />
            </div>
          )}
        {(windowWidth >= 768 || isLeftMenuOpen) && (
          <div className="ml-2">
            <LeftMenu />
          </div>
        )}
      </div>

      {!hasFinnishedTutorial &&
        (windowWidth >= 768 || isLeftMenuOpen) &&
        !inOccupiedCity && (
          <div className="z-[1000] fixed bottom-[-17vw] xs:bottom-[-12vh] sm:bottom-[-9vh] mxl:bottom-[0vh] left-[-5vw] md:left-[-2vh] ">
            <QuestGiverCard />
          </div>
        )}

      {/*RIGHT MENU*/}
      <div
        className={`fixed md:absolute top-20 transition-transform duration-200 ease-in-out   
    ${isCityInfoCardOpen ? "top-24 right-8 z-[11]" : "-right-full md:right-0"}`}
      >
        <div className="relative max-w-[15vw] min-w-[190px] md:min-w-[170px] p-2 rounded max-h-[90vh]   scrollbar scrollbar-thin scrollbar-thumb-cyan-500">
          <CityInfoCard />
        </div>
      </div>

      <div className="fixed inset-x-0 flex justify-center items-end bottom-0 mb-2 z-10">
        <BuildingTimerList />
      </div>

      {windowWidth < 768 && (
        <div
          onClick={() => setLeftMenuOpen(!isLeftMenuOpen)}
          className={`fixed top-[33%] left-0 h-[30vh] bg-gradient-to-r from-mainLightBrown4 to-mainLightBrown2 rounded-r shadow-md md:hidden`}
        >
          <img
            src={arrowImg}
            alt="Right  arrow"
            className="w-8 relative top-[45%]"
          />
        </div>
      )}

      <div
        onClick={() => setCityInfoCardOpen(!isCityInfoCardOpen)}
        className={`fixed top-[33%] right-0 h-[30vh]   bg-gradient-to-l from-mainLightBrown4 to-mainLightBrown2 rounded-l shadow-md md:hidden `}
      >
        <img
          src={arrowImg}
          alt="Right  arrow"
          className="w-8 relative top-[45%] rotate-180"
        />
      </div>

      <div className="fixed bottom-0 left-[8vw] top-[45vh] mb-24"> </div>
    </div>
  );
}

import { useContext, useState } from "react";
import { CityConfigContext } from "../..";

export function ConfirmTokenUseModal({ category, description, closeModal }) {
  const { inCityId } = useContext(CityConfigContext);
  const [status, setStatus] = useState();
  async function useToken() {
    setStatus("");
    const body = {
      cityId: inCityId,
      tokenType: category,
    };

    const res = await fetch(`${import.meta.env.VITE_GAMEHOST}/api/token/use`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(body),
      credentials: "include",
    });

    if (!res.ok) {
      const data = await res.text();
      setStatus(data);
    } else {
      closeModal();
    }
  }

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto flex items-center justify-center">
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div
          onClick={closeModal}
          className="absolute inset-0 bg-gray-500 opacity-75"
        ></div>
      </div>

      <div className="inline-block bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all max-w-lg w-full p-4 mx-10">
        <div className="bg-white">
          <h3 className="text-lg leading-6 font-medium text-gray-900 text-center">
            Confirmation required
          </h3>
          <div className="mt-2 text-gray-500">
            <p className="text-sm  bold font-bold">Are you sure?</p>
            <p> {description}</p>
          </div>
        </div>
        <div className="bg-gray-50 py-3 flex justify-between flex-row-reverse text-white">
          <button
            onClick={useToken}
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-mainLightGreen2 text-base font-medium hover:bg-gray-500  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Use
          </button>
          <button
            onClick={closeModal}
            type="button"
            className="ml-3 inline-flex justify-center bg-red-500 rounded-md border border-gray-300 shadow-sm px-4 py-2 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-0 sm:w-auto sm:text-sm"
          >
            Cancel
          </button>
        </div>
        {status && <h4 className="text-red-500">{status}</h4>}
      </div>
    </div>
  );
}

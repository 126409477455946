import { useFoundryTimerState } from "../contexts/timerContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { TroopCard } from "../cards/TroopCard";
import { useState } from "react";
import { FoundryTimerList } from "../lists/FoundryTimerList";

export function FoundryTroopsList({ level }) {
  const timers = useFoundryTimerState();
  const [isTimersVisible, setIsTimersVisible] = useState(true);

  const toggleBarracksVisibility = () => {
    setIsTimersVisible(!isTimersVisible);
  };
  return (
    <div className="">
      <div className="bg-mainLightBrown1 pl-2 rounded grid grid-cols-1  md:gap-4 lg:grid-cols-1 xl:grid-cols-2">
        <div className="">
          <div className="mt-4 ">
            <div className="bg-mainLightBrown1 pt-2 pl-2 pb-2">
              <div className="max-h-[36vh] overflow-y-scroll scrollbar pr-2 scrollbar-thumb-blue-500 scrollbar-thick  ">
                <TroopCard type="Catapult" />
              </div>
            </div>
          </div>
        </div>

        {timers.length > 0 && (
          <div
            className="flex justify-center mx-auto place-items-center bg-mainDarkBrown rounded text-white items-center mt-2 w-[50%] block xl:hidden"
            onClick={toggleBarracksVisibility}
          >
            <FontAwesomeIcon
              icon={isTimersVisible ? faArrowUp : faArrowDown}
              className=""
            />
            <p className="pl-2 font-semibold">
              {isTimersVisible ? "Hide timers" : "Show timers"}
            </p>
          </div>
        )}

        <div className={`xl:block ${isTimersVisible ? "block" : "hidden"}`}>
          <FoundryTimerList />
        </div>
      </div>
    </div>
  );
}

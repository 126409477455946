import { Link } from "react-router-dom";

import { BuildingInfoImage } from "../../images/BuildingInfoImage";
import { BUILDINGS_AND_PRICES, CONSTANTS } from "../../constants";
import { useEffect, useState } from "react";
import CanUpgradeIcon from "../../images/CanUpgradeIcon.png";
export function BuildingInCityCard({
  upgradeBuilding,
  building,
  buildingStatus,
  buildingLocation,
  cityWarehouse,
}) {
  const [canBuild, setCanBuild] = useState(false);
  function canBuildHandler() {
    if (cityWarehouse && building && building.building_name !== "empty") {
      let buildingKey = building.building_name.split(" ").join("");
      if (buildingKey === "Caligula'sPalace") {
        buildingKey = "CaligulasPalace";
      }
      let foundBuilding = BUILDINGS_AND_PRICES[buildingKey];
      if (!foundBuilding) {
        setCanBuild(false);
        return;
      }

      let currentLevel = building.building_level;
      if (buildingStatus !== null) {
        const lastNumber = buildingStatus.split("->")[1].trim();
        currentLevel = lastNumber;
      }

      let nextBuilding = foundBuilding.levels[currentLevel];

      if (!nextBuilding) {
        setCanBuild(false);
        return;
      }

      if (
        nextBuilding.wheat <= cityWarehouse.wheat &&
        nextBuilding.wood <= cityWarehouse.wood &&
        nextBuilding.stone <= cityWarehouse.stone &&
        nextBuilding.iron <= cityWarehouse.iron
      ) {
        setCanBuild(true);
        return;
      }
    }
    setCanBuild(false);
  }

  useEffect(() => {
    canBuildHandler();
  }, [cityWarehouse]);

  if (building.building_name === "empty") {
    if (buildingStatus) {
      return (
        <div className="bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-xl p-2 transition-all duration-300 ease-in-out cursor-pointer h-full flex items-center justify-center hover:scale-105 hover:shadow-2xl min-w-[80px]">
          <div className="flex items-center justify-center rounded-lg shadow-inner w-full h-full">
            <span className="text-xl font-bold text-mainDarkBrown">
              {buildingStatus}
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="min-h-[150px] bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-xl p-2 transition-colors duration-500 ease-in-out cursor-pointer h-full flex items-center justify-center   hover:scale-[103%] min-w-[80px]">
        <Link
          to={`/buildings/${buildingLocation}`}
          className="text-black flex items-center justify-center rounded-lg shadow-inner w-full h-full"
        >
          Empty
        </Link>
      </div>
    );
  }

  if (building.building_name === "Wall") {
    return (
      <div
        className="flex justify-center mt-2"
        onClick={() => upgradeBuilding(building)}
      >
        {buildingStatus ? (
          <div className="bg-cityCard3 px-5 rounded-full flex justify-center items-center">
            {buildingStatus}
          </div>
        ) : (
          <div className=" px-5 bg-cityCard3 rounded-full flex justify-center items-center hover:scale-[106%]">
            {building.building_level}
          </div>
        )}
        {building.building_name === "under_construction" && (
          <div className=" bg-cityCard3 rounded-full flex justify-center items-center">
            Under construction
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      onClick={() => upgradeBuilding(building)}
      className="bg-gradient-to-br from-cityCard2 to-cityCard1 rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out cursor-pointer aspect-[1/1] w-full hover:scale-[103%] min-w-[80px]"
    >
      <div>
        <BuildingInfoImage type={building.building_name} />

        <div
          className={` bg-cityCard3 rounded-full flex items-center w-full px-2 `} // Added px-2 for some spacing on sides
        >
          <div className="flex-grow text-center text-white">
            {buildingStatus ? (
              <div>{buildingStatus}</div>
            ) : (
              <div>{building.building_level}</div>
            )}
            {building.building_name === "under_construction" && (
              <div>Under construction</div>
            )}
          </div>

          {
            canBuild && (
              <img
                src={CanUpgradeIcon}
                alt="Can upgrade icon"
                className="w-[20px]"
              />
            ) /* : (
              <img
                src={CanNotUpgradeIcon}
                alt="Can not upgrade icon"
                className="w-[20px]"
              />
            ) */
          }
        </div>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import {
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index.jsx";
import { BUILDINGS_AND_PRICES, CONSTANTS } from "../../constants.js";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import { timerActions } from "../reducers/timerReducer";
import { useTimerDispatch } from "../contexts/timerContext";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { HelperFunctions } from "../../HelperFunctions";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive.jsx";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive.jsx";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive.jsx";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive.jsx";
import { ConquererResponsive } from "../../images/troops/ConquererResponsive.jsx";

export function TroopCard(props) {
  const { warehouse } = useContext(WarehouseContext);
  const { inCityId } = useContext(CityConfigContext);
  const dispatch = useTimerDispatch();
  const { canMakeFoundersAmount, canMakeConquererAmount } =
    useContext(CityConfigContext);

  const [canMakeTroop, setCanMakeTroop] = useState(0);
  const [maxOne, setMaxOne] = useState(0);
  const [error, setError] = useState("");
  const { setShowSpinner } = useContext(UserContext);
  const [troopItem, setTroopItem] = useState();
  async function makeTroops() {
    setError("");
    setMaxOne(0);
    setShowSpinner(true);

    if (maxOne === 0) {
      setError("You must select at least one");
    } else if (maxOne <= canMakeTroop) {
      if (
        props.type === "Clubman" ||
        props.type === "Spearman" ||
        props.type === "Axeman" ||
        props.type === "Scout" ||
        props.type === "Guardian" ||
        props.type === "Warlord"
      ) {
        const obj = {
          troopType: props.type,
          cityId: inCityId,
          amount: maxOne,
        };
        const res = await fetch(
          `${import.meta.env.VITE_GAMEHOST}/api/troops/make/`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(obj),
            credentials: "include",
          }
        );

        if (res.status === 200) {
          const data = await res.json();
          const now = new Date().getTime();
          data.timeLeft = Math.floor((data.end_time - now) / 1000);
          const timePassed = now - data.start_time;
          const remainingTimeForNextUnit = Math.ceil(
            (data.time_per_unit - (timePassed % data.time_per_unit)) / 1000
          );
          dispatch({
            type: timerActions.ADD_TIMER,
            category: "trainTroopsTimers",
            payload: {
              duration: data.totalTime,
              timeLeft: data.timeLeft,
              endTime: data.end_time,
              troopType: data.troopType,
              amount: data.amount,
              state: data.state,
              time_per_unit: data.time_per_unit,
              timeLeftNextUnit: remainingTimeForNextUnit,
            },
          });
        }
      } else if (props.type === "Founder" || props.type === "Conquerer") {
        const obj = {
          cityId: inCityId,
          troopType: props.type,
          amount: maxOne,
        };
        const res = await fetch(
          `${import.meta.env.VITE_GAMEHOST}/api/founder/make`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(obj),
            credentials: "include",
          }
        );

        if (res.status === 200) {
          const data = await res.json();
          const now = new Date().getTime();
          data.timeLeft = Math.floor((data.end_time - now) / 1000);
          const timePassed = now - data.start_time;
          const remainingTimeForNextUnit = Math.ceil(
            (data.time_per_unit - (timePassed % data.time_per_unit)) / 1000
          );

          dispatch({
            type: timerActions.ADD_TIMER,
            category: "trainFoundersTimers",
            payload: {
              duration: data.totalTime,
              timeLeft: data.timeLeft,
              endTime: data.end_time,
              troopType: data.troopType,
              amount: data.amount,
              state: data.state,
              time_per_unit: data.time_per_unit,
              timeLeftNextUnit: remainingTimeForNextUnit,
            },
          });
        }
      } else if (props.type === "Catapult") {
        const troopbody = {
          troopType: props.type,
          cityId: inCityId,
          amount: maxOne,
        };

        const res = await fetch(
          `${import.meta.env.VITE_GAMEHOST}/api/foundry/make`,
          {
            method: "POST",
            headers: {
              "content-type": "application/json",
            },
            body: JSON.stringify(troopbody),
            credentials: "include",
          }
        );

        if (res.status === 200) {
          const data = await res.json();
          /*const now = new Date().getTime();
          data.timeLeft = Math.floor((data.end_time - now) / 1000);
          const timePassed = now - data.start_time;
          const remainingTimeForNextUnit = Math.ceil(
            (data.time_per_unit - (timePassed % data.time_per_unit)) / 1000
          ); */

          dispatch({
            type: timerActions.ADD_TIMER,
            category: "foundryTimers",
            payload: {
              duration: data.totalTime,
              timeLeft: data.timeLeft,
              endTime: data.end_time,
              troopType: data.troopType,
              amount: data.amount,
              state: data.state,
              time_per_unit: data.time_per_unit,
              timeLeftNextUnit: data.time_left_next_unit,
            },
          });
        }
      }
    } else {
      setError("You cannot afford that many");
    }
    setTimeout(() => {
      setShowSpinner(false);
    }, 1700);
  }

  useEffect(() => {
    if (props.type) {
      let foundItem = BUILDINGS_AND_PRICES[props.type];
      setTroopItem(foundItem);
    }
  }, [props.type]);

  useEffect(() => {
    if (troopItem) {
      const canMakeResOne = warehouse.wheat / troopItem.levels[0].wheat;
      const canMakeResTwo = warehouse.wood / troopItem.levels[0].wood;
      const canMakeResThree = warehouse.stone / troopItem.levels[0].stone;
      const canMakeResFour = warehouse.iron / troopItem.levels[0].iron;

      if (
        props.type === "Clubman" ||
        props.type === "Spearman" ||
        props.type === "Axeman" ||
        props.type === "Scout" ||
        props.type === "Guardian" ||
        props.type === "Warlord" ||
        props.type === "Catapult"
      ) {
        setCanMakeTroop(
          Math.floor(
            Math.min(
              canMakeResOne,
              canMakeResTwo,
              canMakeResThree,
              canMakeResFour
            )
          )
        );
      } else if (props.type === "Founder") {
        // CanMakeFoundersAmount gets calculated on the server, based on mapchamber level, how many cities are founded and how many founders currently are in queue on in city/not in city
        setCanMakeTroop(
          Math.floor(
            Math.min(
              canMakeResOne,
              canMakeResTwo,
              canMakeResThree,
              canMakeResFour,
              canMakeFoundersAmount
            )
          )
        );
      } else if (props.type === "Conquerer") {
        setCanMakeTroop(
          Math.floor(
            Math.min(
              canMakeResOne,
              canMakeResTwo,
              canMakeResThree,
              canMakeResFour,
              canMakeConquererAmount
            )
          )
        );
      }
    }
  }, [warehouse]);

  if (!troopItem) return undefined;

  return (
    <div className="mt-2 p-2 rounded-lg border-2 border-black bg-cityCard1">
      <h3 className="text-lg mb-2 text-center">
        {props.type === "Clubman" ? (
          CONSTANTS.TRIBE_ONE_TROOP_ONE
        ) : props.type === "Spearman" ? (
          CONSTANTS.TRIBE_ONE_TROOP_TWO
        ) : props.type === "Axeman" ? (
          CONSTANTS.TRIBE_ONE_TROOP_THREE
        ) : props.type === "Founder" ? (
          CONSTANTS.TRIBE_ONE_TROOP_FOUR
        ) : props.type === "Guardian" ? (
          CONSTANTS.TRIBE_ONE_TROOP_FIVE
        ) : props.type === "Warlord" ? (
          CONSTANTS.TRIBE_ONE_TROOP_SIX
        ) : props.type === "Scout" ? (
          CONSTANTS.TRIBE_ONE_TROOP_SEVEN
        ) : props.type === "Catapult" ? (
          CONSTANTS.TRIBE_ONE_TROOP_EIGHT
        ) : props.type === "Conquerer" ? (
          CONSTANTS.TRIBE_ONE_TROOP_NINE
        ) : (
          <></>
        )}
      </h3>
      <div className="flex flex-row sm:flex-row justify-center">
        <div className="flex justify-center">
          {props.type === "Clubman" && <ClubmanResponsive />}
          {props.type === "Spearman" && <SpearmanResponsive />}
          {props.type === "Scout" && <ScoutResponsive />}
          {props.type === "Axeman" && <AxemanResponsive />}
          {props.type === "Founder" && <FounderIconResponsive />}
          {props.type === "Guardian" && <GuardianResponsive />}
          {props.type === "Warlord" && <WarlordResponsive />}
          {props.type === "Catapult" && <CatapultResponsive />}
          {props.type === "Conquerer" && <ConquererResponsive />}
          <input
            type="number"
            value={maxOne}
            onChange={(e) => setMaxOne(parseInt(e.target.value))}
            max={canMakeTroop}
            min={0}
            className="border-2 border-gray-300 rounded px-2 py-1 mr-2 text-lg min-w-[12vw]  sm:min-w-[8vw] text-white"
          />
          <span
            className="cursor-pointer text-blue-600 hover:text-blue-500 text-xs md:text-sm"
            onClick={() => setMaxOne(canMakeTroop)}
          >
            ({canMakeTroop})
          </span>
        </div>

        <button
          className="bg-mainDarkBrown hover:bg-blue-500 text-white font-semibold py-2 px-2 sm:py-2 sm:px-4 rounded transition duration-200 ml-2 text-xs md:text-sm"
          onClick={makeTroops}
        >
          Train
        </button>
      </div>

      <div>
        {troopItem.levels[0].attack_power} Attack power /{" "}
        {troopItem.levels[0].defence_power} Defence power
      </div>

      <div>
        <div className="mt-2 grid place-items-center grid-cols-4">
          <div className="flex ml-2">
            <WheatIconResponsive />
            <h4>{troopItem.levels[0].wheat}</h4>
          </div>
          <div className="flex ml-2">
            <WoodIconReponsive />
            <h4>{troopItem.levels[0].wood}</h4>
          </div>
          <div className="flex ml-2">
            <StoneIconResponsive />
            <h4>{troopItem.levels[0].stone}</h4>
          </div>
          <div className="flex ml-2">
            <IronIconResponsive />
            <h4>{troopItem.levels[0].iron}</h4>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        {HelperFunctions.secondsToTimestamp(
          troopItem.levels[0].seconds_to_build
        )}
      </div>
      {error ? <div className="text-red-700 mt-2">{error}</div> : <></>}
    </div>
  );
}

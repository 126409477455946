import { useEffect, useState } from "react";

export function AchievementsPage() {
  const [achievementsList, setAchievementsList] = useState({});

  async function getAchievements() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/achievements/all`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setAchievementsList(data);
    }
  }

  useEffect(() => {
    getAchievements();
  }, []);

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[14vh] md:top-[10vh]">
        <div className="mx-4 md:mx-0">
          <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-3 max-h-[74vh] min-h-0 overflow-auto rounded-lg shadow-lg">
            <h4 className=" text-3xl text-center font-bold text-gold mb-3">
              Achievements
            </h4>
            <div className="px-4 grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-1 gap-6">
              {Object.entries(achievementsList).map(
                ([key, { progress, goal, stage, displayName }]) => {
                  let percent =
                    goal !== null ? Math.floor((progress / goal) * 100) : 100;
                  let progressText =
                    goal !== null ? `${progress}/${goal}` : `${progress}/-`;
                  let nextStage = stage < 7 ? stage + 1 : "Completed";

                  return (
                    <div
                      key={key}
                      className="bg-mainLightYellow p-4 rounded-md shadow-md transform transition duration-300 hover:scale-105"
                    >
                      <h2 className="text-xl font-semibold text-gray-700 mb-3">
                        {displayName}
                      </h2>

                      <div className="flex items-center justify-between mb-1">
                        <span className="text-xs text-white rounded-full bg-mainLightBrown4 p-1">
                          Stage {stage}
                        </span>
                        <span className="text-xs text-white rounded-full bg-mainLightBrown4 p-1">
                          {" "}
                          {stage !== 7 && <>Next:</>} Stage {nextStage}
                        </span>
                      </div>

                      <div className="h-4 bg-gray-300 rounded relative overflow-hidden">
                        <div
                          className={`h-full bg-gradient-to-r  ${
                            stage !== 7
                              ? "from-mainLightBrown4 to-mainLightBrown2"
                              : "from-cityCard2 to-mainYellow"
                          } text-white text-xs flex items-center justify-center rounded`}
                          style={{ width: `${percent}%` }}
                        >
                          {percent}%
                        </div>
                      </div>

                      <p className="text-sm mt-2 text-gray-700">
                        {progressText}
                      </p>
                    </div>
                  );
                }
              )}
            </div>

            <p className="text-center text-sm mt-6 text-gold font-medium">
              Finish achievements to earn badges for your profile!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

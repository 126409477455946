import AttackActionImage from "../../images/skills/OffensiveSkillIcon.png";
import DefenceActionImage from "../../images/skills/DefensiveSkillIcon.png";
import ResourceActionImage from "../../images/skills/ResourceSkill.png";
import DoubleRewardsActionImage from "../../images/skills/DoubleRewardsSkill.png";
import coins2xActionImage from "../../images/skills/2xCoinsSkill.png";
import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import { HelperFunctions } from "../../HelperFunctions";
import { Tooltip } from "react-tooltip";

export function ActionBar() {
  const { cityAbilities, inCityId } = useContext(CityConfigContext);
  const [hasOffensiveSkill, setHasOffensiveSkill] = useState(false);
  const [hasDefensiveSkill, setHasDefensiveSkill] = useState(false);
  const [hasResourceSkill, setHasResourceSkill] = useState(false);
  const [has2xCoinsSkill, setHas2xCoinsSkill] = useState(false);
  const [hasDoubleRewardsSkill, setHasDoubleRewardSkill] = useState(false);
  const [offensiveCooldown, setOffensiveCooldown] = useState(0);
  const [defensiveCooldown, setDefensiveCooldown] = useState(0);
  const [resourceCooldown, setResourceCooldown] = useState(0);
  const [coins2xCooldown, setCoins2xCooldown] = useState(0);
  const [doubleRewardCooldown, setDoubleRewardCooldown] = useState(0);
  const toolTips = {
    "Attack ability":
      "Double the attack power of your army for 1 hour. (24 Hours Cooldown)",
    "Defence ability":
      "Double the defence power of your army for 1 hour. (24 Hours Cooldown)",
    "Resource ability":
      "Double the resources you get in your city for 30 minutes. (24 Hours Cooldown)",
    "2x coins ability":
      "Coins sell for twice the amount of resources for 2 minutes. (24 Hours Cooldown).",
    "Double rewards ability":
      "Double the rewards gained in Slots and Blackjack for 2 minutes. (24 Hours Cooldown).",
  };

  const useAbility = async (abilityName, cd) => {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/ability/use`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ cityId: inCityId, name: abilityName }),
        credentials: "include",
      }
    );
  };

  useEffect(() => {
    if (cityAbilities && cityAbilities.length > 0) {
      setHasOffensiveSkill(false);
      setHasDefensiveSkill(false);
      setHasResourceSkill(false);
      setHas2xCoinsSkill(false);
      setHasDoubleRewardSkill(false);
      cityAbilities.forEach((ability) => {
        if (ability.name === "Offensive Skill") {
          setHasOffensiveSkill(true);
          setOffensiveCooldown(
            HelperFunctions.calculate24HourCooldown(ability.last_activated)
          );
        } else if (ability.name === "Defensive Skill") {
          setHasDefensiveSkill(true);
          setDefensiveCooldown(
            HelperFunctions.calculate24HourCooldown(ability.last_activated)
          );
        } else if (ability.name === "Resource ability I") {
          setHasResourceSkill(true);
          setResourceCooldown(
            HelperFunctions.calculate24HourCooldown(ability.last_activated)
          );
        } else if (ability.name === "2x Coins") {
          setHas2xCoinsSkill(true);
          setCoins2xCooldown(
            HelperFunctions.calculate24HourCooldown(ability.last_activated)
          );
        } else if (ability.name === "Double rewards") {
          setHasDoubleRewardSkill(true);
          setDoubleRewardCooldown(
            HelperFunctions.calculate24HourCooldown(ability.last_activated)
          );
        }
      });
    }
  }, [cityAbilities]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (offensiveCooldown > 0) {
        setOffensiveCooldown((prev) => prev - 1000);
      }
      if (defensiveCooldown > 0) {
        setDefensiveCooldown((prev) => prev - 1000);
      }
      if (resourceCooldown > 0) {
        setResourceCooldown((prev) => prev - 1000);
      }
      if (coins2xCooldown > 0) {
        setCoins2xCooldown((prev) => prev - 1000);
      }
      if (doubleRewardCooldown > 0) {
        setDoubleRewardCooldown((prev) => prev - 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [
    offensiveCooldown,
    defensiveCooldown,
    resourceCooldown,
    coins2xCooldown,
    doubleRewardCooldown,
  ]);

  return (
    <div className="flex mt-2 p-2 bg-gray-700 rounded shadow-lg w-fit mx-auto space-x-4">
      {hasOffensiveSkill && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content={toolTips["Attack ability"]}
        >
          {" "}
          <div className="relative cursor-pointer hover:scale-105 border-2 border-black">
            <img
              src={AttackActionImage}
              alt="Offensive skill"
              className="w-[50px]"
              onClick={() => useAbility("Offensive Skill")}
            />
            {offensiveCooldown > 0 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm">
                {HelperFunctions.formatTime(offensiveCooldown)}
              </div>
            )}
          </div>
        </a>
      )}
      {hasDefensiveSkill && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content={toolTips["Defence ability"]}
        >
          <div
            className="relative cursor-pointer hover:scale-105 border-2 border-black"
            onClick={() => useAbility("Defensive Skill")}
          >
            <img
              src={DefenceActionImage}
              alt="Defensive skill"
              className="w-[50px]"
            />
            {defensiveCooldown > 0 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm">
                {HelperFunctions.formatTime(defensiveCooldown)}
              </div>
            )}
          </div>
        </a>
      )}
      {hasResourceSkill && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content={toolTips["Resource ability"]}
        >
          <div
            className="relative cursor-pointer hover:scale-105 border-2 border-black"
            onClick={() => useAbility("Resource ability I")}
          >
            <img
              src={ResourceActionImage}
              alt="Resource skill"
              className="w-[50px]"
            />
            {resourceCooldown > 0 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm">
                {HelperFunctions.formatTime(resourceCooldown)}
              </div>
            )}
          </div>
        </a>
      )}
      {has2xCoinsSkill && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content={toolTips["2x coins ability"]}
        >
          <div
            className="relative cursor-pointer hover:scale-105 border-2 border-black"
            onClick={() => useAbility("2x Coins")}
          >
            <img
              src={coins2xActionImage}
              alt="2x coins skill"
              className="w-[50px]"
            />
            {coins2xCooldown > 0 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm">
                {HelperFunctions.formatTime(coins2xCooldown)}
              </div>
            )}
          </div>
        </a>
      )}
      {hasDoubleRewardsSkill && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content={toolTips["Double rewards ability"]}
        >
          <div
            className="relative cursor-pointer hover:scale-105 border-2 border-black"
            onClick={() => useAbility("Double rewards")}
          >
            <img
              src={DoubleRewardsActionImage}
              alt="Double rewards"
              className="w-[50px]"
            />
            {doubleRewardCooldown > 0 && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-sm">
                {HelperFunctions.formatTime(doubleRewardCooldown)}
              </div>
            )}
          </div>
        </a>
      )}

      <Tooltip anchorSelect=".my-anchor-element"></Tooltip>
    </div>
  );
}

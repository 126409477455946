import {BuildingDescAndImage} from "../../cards/BuildingDescAndImage";
import {useContext} from "react";
import {CityConfigContext} from "../../../index";

export function Wall({level}) {
    const { cityBonus } = useContext(CityConfigContext);
    return <div>
        <BuildingDescAndImage type="Wall" isDetails={true}/>

        <div className="bg-mainLightBrown1 w-fit p-4">
            <h4>Defence bonus from skilltree defence trait: {cityBonus.defence_bonus > 0 ? cityBonus.defence_bonus  - (level * cityBonus.wall_skill_rank) : cityBonus.defence_bonus }%</h4>
            <h4>Defence bonus from wall: Wall level {level} x Wall skill rank {cityBonus.wall_skill_rank} =  {level * cityBonus.wall_skill_rank}%</h4>
            <h4>Your troops have {cityBonus.defence_bonus}% stronger defence.</h4>

        </div>
    </div>
}
import { useState } from "react";
import { AttackActivityCard } from "../cards/AttackActivityCard";
import {
  useIncomingAttackTimerState,
  useIncomingReinforcementTimerState,
  useOutgoingAttackTimerState,
  useOutgoingReinforcementTimerState,
  useReturningAttackTimer,
} from "../contexts/timerContext";

export function CityActivityOverView() {
  const incoming = useIncomingAttackTimerState();
  const outgoing = useOutgoingAttackTimerState();
  const returning = useReturningAttackTimer();
  const outgoingReinforcementTimers = useOutgoingReinforcementTimerState();
  const incomingReinforcementTimers = useIncomingReinforcementTimerState();
  const [showList, setShowList] = useState({
    incoming: true,
    outgoing: true,
    returning: true,
    incomingReinforcement: true,
    outgoingReinforcement: true,
  });

  const toggleList = (type) => {
    setShowList({ ...showList, [type]: !showList[type] });
  };

  return (
    <div className="grid grid-cols-1 gap-4 ">
      {/* Incoming attacks */}
      <div
        className={` ${
          showList.incoming ? "overflow-y-auto" : "overflow-hidden"
        }`}
      >
        <div className="bg-mainDefend py-2 px-4 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-white">Incoming attacks</h3>
          <svg
            onClick={() => toggleList("incoming")}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
              showList.incoming ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {showList.incoming && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-2 p-2 bg-mainDefend">
            {incoming.length > 0 ? (
              incoming.map((incomingItem) => (
                <div className="bg-mainLightBrown4 p-2 rounded">
                  <AttackActivityCard data={incomingItem} type="incoming" />
                </div>
              ))
            ) : (
              <div className="py-2 px-4 text-white">No incoming attacks</div>
            )}
          </div>
        )}
      </div>

      {/* Outgoing attacks */}
      <div
        className={`max-h-[300px] ${
          showList.incoming ? "overflow-y-auto" : "overflow-hidden"
        }`}
      >
        <div className="bg-mainAttack py-2 px-4 flex justify-between items-center ">
          <h3 className="text-lg font-semibold text-white">Outgoing attacks</h3>
          <svg
            onClick={() => toggleList("outgoing")}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
              showList.outgoing ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L           10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {showList.outgoing && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-2 p-2 bg-mainAttack ">
            {outgoing.length > 0 ? (
              outgoing.map((outgoingItem) => (
                <div className="bg-mainLightBrown4 p-2 rounded">
                  <AttackActivityCard data={outgoingItem} type="outgoing" />
                </div>
              ))
            ) : (
              <div className="py-2 px-4 text-white">No outgoing attacks</div>
            )}
          </div>
        )}
      </div>

      {/* Retrieving troops */}
      <div
        className={`max-h-[300px] ${
          showList.incoming ? "overflow-y-auto" : "overflow-hidden"
        }`}
      >
        <div className="bg-mainReturn py-2 px-4 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-white">
            Retrieving troops
          </h3>
          <svg
            onClick={() => toggleList("returning")}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
              showList.returning ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {showList.returning && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-2 p-2 bg-mainReturn">
            {returning.length > 0 ? (
              returning.map((attack) => (
                <div className="bg-mainLightBrown4 p-2 rounded">
                  <AttackActivityCard data={attack} type="returning" />
                </div>
              ))
            ) : (
              <div className="py-2 px-4 text-white">No retrieving troops</div>
            )}
          </div>
        )}
      </div>

      {/* Outgoing reinforcement */}
      <div
        className={`max-h-[300px] ${
          showList.incomingReinforcement ? "overflow-y-auto" : "overflow-hidden"
        }`}
      >
        <div className="bg-mainReinforcement2 py-2 px-4 flex justify-between items-center ">
          <h3 className="text-lg font-semibold text-white">
            Outgoing reinforcements
          </h3>
          <svg
            onClick={() => toggleList("outgoingReinforcement")}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
              showList.outgoingReinforcement ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L           10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {showList.outgoingReinforcement && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-2 p-2 bg-mainReinforcement2 ">
            {outgoingReinforcementTimers.length > 0 ? (
              outgoingReinforcementTimers.map((outgoingItem) => (
                <div className="bg-mainReinforcement2 p-2 rounded">
                  <AttackActivityCard
                    data={outgoingItem}
                    type="outgoingReinforcement"
                  />
                </div>
              ))
            ) : (
              <div className="py-2 px-4 text-white">
                No outgoing reinforcements
              </div>
            )}
          </div>
        )}
      </div>
      {/* Incoming reinforcements */}
      <div
        className={` ${
          showList.incomingReinforcement ? "overflow-y-auto" : "overflow-hidden"
        }`}
      >
        <div className="bg-mainReinforcement1 py-2 px-4 flex justify-between items-center">
          <h3 className="text-lg font-semibold text-white">
            Incoming reinforcements
          </h3>
          <svg
            onClick={() => toggleList("incomingReinforcement")}
            xmlns="http://www.w3.org/2000/svg"
            className={`h-5 w-5 text-white cursor-pointer transform transition duration-300 ${
              showList.incomingReinforcement ? "rotate-0" : "rotate-180"
            }`}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M11.293 9.293a1 1 0 00-1.414 0l-3 3a1 1 0 001.414 1.414L10 11.414l2.293 2.293a1 1 0 001.414-1.414l-3-3z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        {showList.incomingReinforcement && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2  lg:grid-cols-3 gap-2 p-2 bg-mainReinforcement1">
            {incomingReinforcementTimers.length > 0 ? (
              incomingReinforcementTimers.map((incomingItem) => (
                <div className="bg-mainLightBrown4 p-2 rounded">
                  <AttackActivityCard
                    data={incomingItem}
                    type="incomingReinforcement"
                  />
                </div>
              ))
            ) : (
              <div className="py-2 px-4 text-white">
                No incoming reinforcements
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

import { useContext } from "react";
import { CityConfigContext } from "../..";

export function SlotsVictoryModal({
  isOpen,
  onClose,
  winningIcon,
  setLootCollected,
}) {
  const { inCityId } = useContext(CityConfigContext);
  if (!isOpen) {
    return null;
  }

  async function collectLoot() {
    let winnerText = "";

    if (winningIcon === "🥑") {
      winnerText = "avocado";
    }
    if (winningIcon === "🍇") {
      winnerText = "grapes";
    }
    if (winningIcon === "🍒") {
      winnerText = "cherry";
    }
    if (winningIcon === "🍉") {
      winnerText = "watermelon";
    }

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/slots/collect`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ winningIcon: winnerText, cityId: inCityId }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setLootCollected((prev) => !prev);
      onClose();
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-r from-yellow-400 via-red-500 to-pink-500 relative flex justify-center items-center overflow-hidden rounded-xl outline-none p-8">
        <div className="text-center">
          <h2 className="text-4xl text-white font-bold mb-4">
            Congratulations!
          </h2>
          <p className="text-xl text-white">You had three {winningIcon}'s</p>

          <button
            className="mt-4 bg-white text-red-500 px-6 py-2 rounded-full shadow-md font-bold"
            onClick={collectLoot}
          >
            Collect
          </button>
        </div>
      </div>
    </div>
  );
}

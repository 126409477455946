import Conquerer from "./ConquererIcon.png";

export function ConquererResponsive() {
  return (
    <img
      src={Conquerer}
      alt={`Conquerer icon`}
      className="w-6 h-6 min-w-[20px] xs:w-8 xs:h-8 mr-2"
    />
  );
}

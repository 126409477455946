import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { AllianceInfoCard } from "../Components/cards/AllianceInfoCard";

export function AlliancePublicPage() {
  const { allianceTag } = useParams();

  const [allianceData, setAllianceData] = useState();
  const [error, setError] = useState();

  async function getData() {
    setError(undefined);
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/alliance/details/public/${allianceTag}`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      const data = await res.json();
      setAllianceData(data);
    }

    if (res.status === 404) {
      setError("Alliance does not exist");
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!allianceData) {
    return <div>Loading..</div>;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative  top-[10vh]">
        <div className="w-2/3 mx-auto bg-mainDarkBrown p-4 min-w-[360px] overflow-auto">
          <div className="bg-mainLightYellow p-4  max-h-[70vh] min-h-0 min-w-[320px] overflow-auto">
            <AllianceInfoCard allianceDetails={allianceData} />
          </div>
        </div>
      </div>
    </div>
  );
}

import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import WheatField from "../../images/resourceFields/WheatField2.png";
import WoodField from "../../images/resourceFields/WoodField.png";
import StoneField from "../../images/resourceFields/StoneField.png";
import IronField from "../../images/resourceFields/IronField.png";
import { BUILDINGS_AND_PRICES } from "../../constants";
import CanUpgradeIcon from "../../images/CanUpgradeIcon.png";
import CanNotUpgradeIcon from "../../images/CanNotUpgradeIcon.png";
export function ResourceSpot({ newField, type, warehouse }) {
  const { setSelectedResField } = useContext(CityConfigContext);

  const [canBuild, setCanBuild] = useState(false);
  function canBuildHandler() {
    if (warehouse) {
      let nextLevel = newField.level;
      if (typeof newField.status === "string") {
        const lastNumber = newField.status.split("->")[1].trim();
        nextLevel = lastNumber;
      }
      let nextField = BUILDINGS_AND_PRICES[newField.type].levels[nextLevel];
      if (!nextField) {
        setCanBuild(false);
        return;
      }

      if (
        nextField.wheat <= warehouse.wheat &&
        nextField.wood <= warehouse.wood &&
        nextField.stone <= warehouse.stone &&
        nextField.iron <= warehouse.iron
      ) {
        setCanBuild(true);
        return;
      }
    }

    setCanBuild(false);
  }
  const navigate = useNavigate();
  const [isClicked, setClicked] = useState(false);
  const fieldImage =
    type === "wheat"
      ? WheatField
      : type === "wood"
      ? WoodField
      : type === "stone"
      ? StoneField
      : type === "iron"
      ? IronField
      : null;
  const bgGradient =
    type === "wheat"
      ? "from-cityCard1 to-gradientWheat1"
      : type === "wood"
      ? "from-gradientWood2 to-gradientWood1"
      : type === "stone"
      ? "from-mainLightBrown1 to-gradientStone2"
      : type === "iron"
      ? "from-gradientIron1 to-gradientIron2"
      : null;

  const lvlBgColor =
    type === "wheat"
      ? "bg-wheatUpgradeButton"
      : type === "wood"
      ? "bg-woodUpgradeButton"
      : type === "stone"
      ? "bg-stoneUpgradeButton"
      : type === "iron"
      ? "bg-ironUpgradeButton"
      : null;

  function upgradeResource(resourceField) {
    setSelectedResField(resourceField);
    localStorage.setItem("selectedResField", JSON.stringify(resourceField));
    navigate("/resourcefield/upgrade");
    setClicked(true);
    setTimeout(() => setClicked(false), 200); // Reset isClicked state after 200ms
  }
  if (fieldImage === null) return null;

  useEffect(() => {
    canBuildHandler();
  }, [warehouse]);

  return (
    <div
      className={`bg-gradient-to-br ${bgGradient} rounded-lg shadow-lg p-2 transition-colors duration-500 ease-in-out hover:scale-[103%] cursor-pointer aspect-[1/1] w-full`}
      onClick={() => upgradeResource(newField)}
    >
      <div className="aspect-content">
        <img
          src={fieldImage}
          alt="Resource field image"
          className={`w-full h-full object-cover rounded-lg transition-transform duration-500 ${
            isClicked ? "animate-pulse" : ""
          }`}
        />
        <div
          className={`mt-2 ${lvlBgColor} rounded-full flex items-center w-full px-2 `} // Added px-2 for some spacing on sides
        >
          <div className="flex-grow text-center text-white">
            {newField.status ? newField.status : newField.level}
          </div>
          <div className="flex justify-end">
            {
              canBuild && (
                <img
                  src={CanUpgradeIcon}
                  alt="Can upgrade icon"
                  className="w-[20px]"
                />
              ) /* : (
              <img
                src={CanNotUpgradeIcon}
                alt="Can not upgrade icon"
                className="w-[20px]"
              />
            ) */
            }
          </div>
        </div>
      </div>
    </div>
  );
}

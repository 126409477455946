import { useContext, useEffect, useState } from "react";
import {
  AuthContext,
  CityConfigContext,
  UserContext,
  WarehouseContext,
} from "../../index.jsx";
import { Link } from "react-router-dom";
import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import { RotatingLines } from "react-loader-spinner";
import SignOutIcon from "../../images/navigations/SignOutNav.png";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn } from "@fortawesome/free-solid-svg-icons";
import { HelperFunctions } from "../../HelperFunctions.jsx";

export function ResourceBar() {
  const { warehouse, setWarehouse } = useContext(WarehouseContext);
  const { cityProductionStats } = useContext(CityConfigContext);
  const { showSpinner } = useContext(UserContext);
  const { cityBonus } = useContext(CityConfigContext);
  const navigate = useNavigate();

  const [cookies, setCookies, removeCookies] = useCookies(["authToken"]);
  const { setAuthToken } = useContext(AuthContext);

  const jwt = Cookies.get("authToken");

  const [wheatPerSec, setWheatPerSec] = useState(0);
  const [woodPerSec, setWoodPerSec] = useState(0);
  const [stonPerSec, setStonePerSec] = useState(0);
  const [ironPerSec, setIronPerSec] = useState(0);
  const [warehouseCapacity, setWarehouseCapacity] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setWarehouse((prevWarehouse) => ({
        ...prevWarehouse,
        wheat: Math.min(prevWarehouse.wheat + wheatPerSec, warehouseCapacity),
        wood: Math.min(prevWarehouse.wood + woodPerSec, warehouseCapacity),
        stone: Math.min(prevWarehouse.stone + stonPerSec, warehouseCapacity),
        iron: Math.min(prevWarehouse.iron + ironPerSec, warehouseCapacity),
      }));
    }, 1000);
    return () => clearInterval(interval);
  }, [wheatPerSec, woodPerSec, stonPerSec, ironPerSec, warehouseCapacity]);

  useEffect(() => {
    if (cityProductionStats && cityBonus) {
      setWheatPerSec(
        (cityProductionStats.wheat_per_min / 60) *
          (1 + cityBonus.wheat_bonus / 100)
      );
      setWoodPerSec(
        (cityProductionStats.wood_per_min / 60) *
          (1 + cityBonus.wood_bonus / 100)
      );
      setStonePerSec(
        (cityProductionStats.stone_per_min / 60) *
          (1 + cityBonus.stone_bonus / 100)
      );
      setIronPerSec(
        (cityProductionStats.iron_per_min / 60) *
          (1 + cityBonus.iron_bonus / 100)
      );
      setWarehouseCapacity(cityProductionStats.warehouse_capacity);
    }
  }, [cityProductionStats, cityBonus]);

  useEffect(() => {
    if (!jwt) {
      // should disconnect client?
      navigate("/login");
    }
  }, [jwt]);

  async function logOut() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/logout`,
      {
        credentials: "include",
      }
    );

    if (res.ok) {
      setAuthToken(undefined);
      removeCookies("authToken");
      navigate("/login");
    } else {
      removeCookies("authToken");
    }

    localStorage.clear();
  }

  if (!warehouse) {
    return <div>loading..</div>;
  }

  return (
    <div className="bg-resCounterBg rounded-md text-xxs xs:text-xs sm:text-sm ">
      <div className="flex justify-center">
        <div className="grid grid-cols-2 xxxs:grid-cols-4 gap-2 max-w-screen-md mx-auto rounded p-2">
          <div className="flex flex-wrap items-center">
            <WheatIconResponsive />

            {/* Fraction display for small screens */}
            <div className="font-bold ml-1 flex flex-col items-center sm:hidden">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.wheat)
                )}
              </span>
              <div className="w-full h-px bg-white my-0.5"></div>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>

            {/* Slash display for medium and larger screens */}
            <div className="font-bold ml-1 hidden sm:flex sm:items-baseline">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.wheat)
                )}
              </span>
              <span className="mx-1 text-xs">/</span>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap items-center">
            <WoodIconReponsive />

            {/* Fraction display for small screens */}
            <div className="font-bold ml-1 flex flex-col items-center sm:hidden">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.wood)
                )}
              </span>
              <div className="w-full h-px bg-white my-0.5"></div>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>

            {/* Slash display for medium and larger screens */}
            <div className="font-bold ml-1 hidden sm:flex sm:items-baseline">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.wood)
                )}
              </span>
              <span className="mx-1 text-xs">/</span>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap items-center">
            <StoneIconResponsive />

            {/* Fraction display for small screens */}
            <div className="font-bold ml-1 flex flex-col items-center sm:hidden">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.stone)
                )}
              </span>
              <div className="w-full h-px bg-white my-0.5"></div>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>

            {/* Slash display for medium and larger screens */}
            <div className="font-bold ml-1 hidden sm:flex sm:items-baseline">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.stone)
                )}
              </span>
              <span className="mx-1 text-xs">/</span>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>
          </div>

          <div className="flex flex-wrap items-center">
            <IronIconResponsive />

            {/* Fraction display for small screens */}
            <div className="font-bold ml-1 flex flex-col items-center sm:hidden">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.iron)
                )}
              </span>
              <div className="w-full h-px bg-white my-0.5"></div>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>

            {/* Slash display for medium and larger screens */}
            <div className="font-bold ml-1 hidden sm:flex sm:items-baseline">
              <span className="block">
                {HelperFunctions.turnNumberToNumberic(
                  Math.floor(warehouse.iron)
                )}
              </span>
              <span className="mx-1 text-xs">/</span>
              <span className="block">
                {cityProductionStats
                  ? HelperFunctions.turnNumberToNumberic(
                      cityProductionStats.warehouse_capacity
                    )
                  : 0}
              </span>
            </div>
          </div>
        </div>
        <div className=" transition duration-300 ease-in-out cursor-pointer grid grid-cols-2 ">
          <div className="hover:bg-blue-700 flex justify-center place-items-center text-center">
            <Link to={`/news`} className="text-blue-400 ">
              <FontAwesomeIcon icon={faBullhorn} className="mr-2 fa-regular" />
            </Link>
          </div>
          <p className="flex items-center p-2 text-gray-800 hover:bg-red-700">
            <img
              src={SignOutIcon}
              className="mr-2 w-8"
              alt="Sign out icon"
              onClick={logOut}
            />
          </p>
        </div>
      </div>
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../..";
import { CasinoCoinResponsive } from "../../../images/CasinoCoinResponsive";

export function SlotRewards() {
  const { cityBonus } = useContext(CityConfigContext);
  const [watermelonValue, setWatermelonValue] = useState(10);
  const [avocadoValue, setAvocadoValue] = useState(25);
  const [cherryValue, setCherryValue] = useState(100);
  const [grapeValue, setGrapeValue] = useState(1000);
  useEffect(() => {
    if (cityBonus) {
      let abilityIsActive = false;
      if (cityBonus.active_abilities.length > 0) {
        cityBonus.active_abilities.forEach((item) => {
          if (item.name === "Double rewards") {
            abilityIsActive = true;
          }
        });
      }
      if (
        (abilityIsActive && !cityBonus.has_slot_machine_II) ||
        (!abilityIsActive && cityBonus.has_slot_machine_II)
      ) {
        setWatermelonValue(10 * 2);
        setAvocadoValue(25 * 2);
        setCherryValue(100 * 2);
        setGrapeValue(1000 * 2);
      } else if (abilityIsActive && cityBonus.has_slot_machine_II) {
        setWatermelonValue(10 * 3);
        setAvocadoValue(25 * 3);
        setCherryValue(100 * 3);
        setGrapeValue(1000 * 3);
      } else {
        setWatermelonValue(10);
        setAvocadoValue(25);
        setCherryValue(100);
        setGrapeValue(1000);
      }
    }
  }, [cityBonus]);

  return (
    <div className="bg-blue-600 p-2 rounded-lg shadow-xl w-auto inline-flex flex-col items-center m-4 border-4 border-yellow-400">
      <h1 className="text-2xl text-yellow-300 font-bold mb-2 underline">
        Rewards:
      </h1>
      <ul className="space-y-2 text-center text-base  md:text-lg lg:text-sm xl:text-xl">
        <li className=" text-white flex items-center justify-between space-x-1">
          <span>🍉🍉🍉</span>
          <div className="flex">
            <span className="text-yellow-300">{watermelonValue} </span>
            <CasinoCoinResponsive />
          </div>
        </li>
        <li className=" text-white flex items-center justify-between space-x-1">
          <span>🥑🥑🥑</span>
          <div className="flex">
            <span className="text-yellow-300">{avocadoValue} </span>
            <CasinoCoinResponsive />
          </div>
        </li>
        <li className=" text-white flex items-center justify-between space-x-1">
          <span>🍒🍒🍒</span>
          <div className="flex">
            <span className="text-yellow-300">{cherryValue} </span>
            <CasinoCoinResponsive />
          </div>
        </li>
        <li className=" text-white flex items-center justify-between space-x-1">
          <span>🍇🍇🍇</span>
          <div className="flex">
            <span className="text-yellow-300">{grapeValue} </span>
            <CasinoCoinResponsive />
          </div>
        </li>
      </ul>
    </div>
  );
}

import FounderIcon from "./FounderIcon.png";

export function FounderIconResponsive() {
  return (
    <img
      src={FounderIcon}
      alt={`Founder icon`}
      className="w-6 h-6 min-w-[20px] xs:w-8 xs:h-8 mr-2"
    />
  );
}

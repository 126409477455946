import { useState } from "react";
import { MessageList } from "../Components/lists/MessageList";
import { NewMessageCard } from "../Components/cards/NewMessageCard";
import { useLocation } from "react-router-dom";

export function MessagePage() {
  const location = useLocation();
  const shouldReply = location.state?.reply || false;
  const [tabOption, setTabOption] = useState(shouldReply ? "new" : "inbox");
  function changeView(option) {
    setTabOption(option);
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[10vh]">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
          <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-4">
            <div className="grid place-items-center ">
              <ul className="flex text-xxs sm:text-sm md:text-base lg:text-lg xl:text-lg text-white pb-4">
                <li>
                  <button
                    className="bg-mainDarkBrown rounded-none"
                    onClick={() => changeView("inbox")}
                  >
                    Inbox
                  </button>
                </li>
                <li className="ml-2">
                  <button
                    className="bg-mainDarkBrown rounded-none"
                    onClick={() => changeView("sendt")}
                  >
                    Sent
                  </button>
                </li>
                <li className="ml-2">
                  <button
                    className="bg-mainDarkBrown rounded-none"
                    onClick={() => changeView("new")}
                  >
                    New
                  </button>
                </li>
              </ul>
            </div>

            <div className="h-full mt-2 flex overflow-x-auto w-full">
              {tabOption === "sendt" ? (
                <MessageList tab="sendt" />
              ) : tabOption === "inbox" ? (
                <MessageList tab="inbox" />
              ) : tabOption === "new" ? (
                <NewMessageCard
                  naver={setTabOption}
                  routeState={location.state}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

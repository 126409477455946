import { useEffect, useRef, useState } from "react";
import FillWarehouseTokenImage from "../images/tokens/FillWarehouseToken.png";
import ResourceBoostTokenImage from "../images/tokens/ResourceBoostToken.png";
import TroopTrainingTokenImage from "../images/tokens/TroopsTrainingToken.png";
import ExtraQueueItemTokenImage from "../images/tokens/ExtraQueueItemToken.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { ConfirmTokenUseModal } from "../Components/modals/ConfirmTokenUseModal";

export function CityToken({ category, value }) {
  const [tokenImage, setTokenImage] = useState();
  const [tokenDescription, setTokenDescription] = useState();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  // Close the info box if clicked outside
  useEffect(() => {
    if (category === "fill_warehouse_count") {
      setTokenImage(FillWarehouseTokenImage);
      setTokenDescription(
        "Using this token will fill your warehouse to the max."
      );
    } else if (category === "day_hour_resource_boost_count") {
      setTokenImage(ResourceBoostTokenImage);
      setTokenDescription(
        "Using this token will give you 30% more resources for 24 hours."
      );
    } else if (category === "day_hour_troop_training_boost_count") {
      setTokenImage(TroopTrainingTokenImage);
      setTokenDescription(
        "Using this token will make your troops train 40% faster for 24 hours."
      );
    } else if (category === "day_hour_extra_building_in_queue_count") {
      setTokenImage(ExtraQueueItemTokenImage);
      setTokenDescription(
        "Using this token will let you add an extra building to your queue for 24 hours."
      );
    }
  }, []);
  function closeModal(e) {
    setShowConfirmationModal(false);
  }

  return (
    <div className="relative">
      <div className="bg-gradient-to-br from-cityCard2 to-cityCard1 p-2 rounded-md shadow-md text-center flex flex-col place-items-center">
        <img
          src={tokenImage}
          alt="Token image"
          onClick={() =>
            setShowConfirmationModal(showConfirmationModal ? false : true)
          }
          className="w-24 hover:scale-[105%]"
        />
        <p className="font-bold text-white">{value}</p>
      </div>
      {showConfirmationModal && (
        <ConfirmTokenUseModal
          category={category}
          description={tokenDescription}
          closeModal={closeModal}
        />
      )}
    </div>
  );
}

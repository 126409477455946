import Catapult from "./CatapultIcon.png";

export function CatapultResponsive() {
  return (
    <img
      src={Catapult}
      alt={`Catapult icon`}
      className="w-6 h-6 min-w-[20px] xs:w-8 xs:h-8 mr-2"
    />
  );
}

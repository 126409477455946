import { useContext, useEffect, useState } from "react";
import { AllianceInfoCard } from "../Components/cards/AllianceInfoCard";
import { AllianceInviteCard } from "../Components/cards/AllianceInviteCard";
import { AllianceSettingsCard } from "../Components/cards/AllianceSettingsCard";
import { AllianceContext } from "../index";
import { AllianceActivityList } from "../Components/lists/AllianceActivityList";
import { HighScoreNavDropDown } from "../Components/lists/HighScoreNavDropDown";

export function AllianceHomePage() {
  const { allianceRole, allianceData } = useContext(AllianceContext);
  const [activeTab, setActiveTab] = useState("info");

  const [isOuterDropDownOpen, setIsOuterDropDownOpen] = useState(false);

  function changeView(option) {
    setActiveTab(option);
  }

  const options = [
    { value: "info", text: "Info" },
    { value: "invite", text: "Invite" },
    { value: "activity", text: "Activity" },
    { value: "settings", text: "Setting" },
  ];

  useEffect(() => {
    setIsOuterDropDownOpen(false);
  }, [activeTab]);

  if (!allianceData || !allianceRole) {
    return (
      <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
        <div className="relative  top-[10vh] mx-4">
          <div className="md:w-2/3 mx-auto bg-mainDarkBrown p-4">
            <div className="bg-mainLightYellow p-4  max-h-[70vh] min-h-0 overflow-auto flex justify-center">
              You are not part of an alliance
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative  top-[10vh] mx-4">
        <div className="md:w-2/3 mx-auto bg-mainDarkBrown p-4">
          <div className="bg-mainLightYellow p-4  max-h-[70vh] min-h-0 overflow-auto">
            <div className="flex justify-center">
              <div className="flex md:flex hidden text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
                <button
                  className={`bg-mainDarkBrown rounded-none mr-2 ${
                    activeTab === "info" && "bg-cyan-700"
                  }`}
                  onClick={() => changeView("info")}
                >
                  Info
                </button>
                {allianceRole.caninvite && (
                  <button
                    className={`bg-mainDarkBrown rounded-none mr-2 ${
                      activeTab === "invite" && "bg-cyan-700"
                    }`}
                    onClick={() => changeView("invite")}
                  >
                    Invite
                  </button>
                )}

                <button
                  className={`bg-mainDarkBrown rounded-none mr-2 ${
                    activeTab === "activity" && "bg-cyan-700"
                  }`}
                  onClick={() => changeView("activity")}
                >
                  Activity
                </button>

                <button
                  className={`bg-mainDarkBrown rounded-none mr-2 ${
                    activeTab === "settings" && "bg-cyan-700"
                  }`}
                  onClick={() => changeView("settings")}
                >
                  Settings
                </button>
              </div>
              <div onClick={() => setIsOuterDropDownOpen(!isOuterDropDownOpen)}>
                <HighScoreNavDropDown
                  options={options}
                  onOptionChange={(value) => changeView(value)}
                  showOption={activeTab}
                />
              </div>
            </div>

            <div className="h-full mt-2   w-full min-h-[50vh]  overflow-auto">
              {activeTab === "info" && (
                <AllianceInfoCard allianceDetails={allianceData} />
              )}
              {activeTab === "invite" && (
                <AllianceInviteCard
                  role={allianceRole}
                  allianceId={allianceRole.alliance_id}
                  currentMembers={allianceData.details[0].alliance_members}
                  maxMembers={allianceData.details[0].alliance_max_members}
                />
              )}
              {activeTab === "activity" && (
                <AllianceActivityList allianceId={allianceRole.alliance_id} />
              )}
              {activeTab === "settings" && (
                <AllianceSettingsCard
                  role={allianceRole}
                  members={allianceData.users}
                  isOuterDropDownOpen={isOuterDropDownOpen}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import Scout from "./ScoutIcon.png";

export function ScoutResponsive() {
  return (
    <img
      src={Scout}
      alt={`Scout icon`}
      className="w-6 h-6 min-w-[20px] xs:w-8 xs:h-8 mr-2"
    />
  );
}

import { React, useContext, useEffect, useState } from "react";
import { NotificationContext } from "../index.jsx";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { PaginationControls } from "../Components/PaginationControls";

export function NotificationsPage() {
  const { setNotificationDetailsId } = useContext(NotificationContext);
  const [allNotifications, setAllNotifications] = useState([]);
  const [hasNotifications, setHasNotifications] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [selectedNotifications, setSelectedNotifications] = useState([]);
  const { hasNewNotification, setHasNewNotification } =
    useContext(NotificationContext);

  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(undefined);
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  const navigate = useNavigate();

  function toggleNotificationSelection(notification) {
    const index = selectedNotifications.findIndex(
      (m) => m.id === notification.id
    );
    if (index > -1) {
      // Message is already selected, so remove it
      setSelectedNotifications(
        selectedNotifications.filter((m) => m.id !== notification.id)
      );
    } else {
      // Message is not selected, so add it
      setSelectedNotifications([...selectedNotifications, notification]);
    }
  }

  function toggleSelectAll(e) {
    setSelectAllChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedNotifications(allNotifications);
    } else {
      setSelectedNotifications([]);
    }
  }

  async function deleteNotifications() {
    const notificationIds = selectedNotifications.map(
      (notification) => notification.id
    );

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/notification/delete`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ selectedNotifications: notificationIds }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setSelectAllChecked(false);
      const unseenNotifications = selectedNotifications.filter(
        (item) => item.hasseen === false
      );
      if (unseenNotifications.length > 0) {
        setHasNewNotification(hasNewNotification - unseenNotifications.length);
      }
      setSelectedNotifications([]);
      await getDataCount();
      await getAllNotifications();
    }
  }

  const getDataCount = async () => {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/notification/count/all`,
      {
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setNotificationCount(2000);
      const data = await res.json();
      setTotalPageCount(data);
    }

    if (res.status === 404) {
      setHasNotifications(false);
      setNotificationCount(0);
    }
  };

  useEffect(() => {
    const getData = async () => {
      await getAllNotifications();
    };
    getData();
  }, [pageNumber]);

  async function getAllNotifications() {
    if (notificationCount > 0) {
      const res = await fetch(
        `${import.meta.env.VITE_GAMEHOST}/api/notification/all/${pageNumber}`,
        {
          credentials: "include",
        }
      );
      if (res.status === 200) {
        const data = await res.json();
        setAllNotifications(data);
        setHasNotifications(true);
      }

      if (res.status === 404) {
        setHasNotifications(false);
      }
    }
  }

  useEffect(() => {
    getDataCount();
    getAllNotifications();
  }, [notificationCount]);

  if (!allNotifications) {
    return <div>Loading...</div>;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden text-sm sm:text-sm md:text-base lg:text-lg xl:text-xl">
      <div className="relative top-[10vh]">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
          <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-4">
            <div className="h-full mt-2 flex overflow-x-auto w-full">
              <div className="w-full overflow-x-auto sm:overflow-x-auto text-sm sm:text-sm md:text-base lg:text-lg xl:text-xl">
                <div>
                  {/*FOR MEDIUM AND BIG SCREENS*/}

                  <div className="hidden sm:block">
                    <div className="grid grid-cols-4 gap-4 bg-mainLightBrown1 text-center pt-2 pb-2 font-semibold ">
                      <div>
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={toggleSelectAll}
                          className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                        />
                        <span className="ml-2">Select All</span>
                      </div>
                      <div>Opened:</div>
                      <div>Time:</div>
                      <div>Event:</div>
                    </div>

                    <div>
                      {hasNotifications ? (
                        allNotifications.map((notification, index) => (
                          <div
                            key={index}
                            className={`grid grid-cols-4  ${
                              index % 2 === 0
                                ? "bg-mainLightBrown3"
                                : "bg-mainLightBrown1"
                            } text-center pt-2 pb-2  place-items-center `}
                            onClick={() => {
                              setNotificationDetailsId(notification.id);
                              navigate("/notificationDetails");
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedNotifications.some(
                                (m) => m.id === notification.id
                              )}
                              onClick={(e) => e.stopPropagation()}
                              onChange={(e) => {
                                toggleNotificationSelection(notification);
                              }}
                              className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                            />
                            {notification.hasseen ? (
                              <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500">
                                {" "}
                              </div>
                            ) : (
                              <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-red-400">
                                {" "}
                              </div>
                            )}
                            <div>{notification.time}</div>

                            <h4 className="text-xs">{notification.event}</h4>
                          </div>
                        ))
                      ) : (
                        <div className="text-black p-2 bg-mainDarkGreen text-center">
                          You have no notifications yet!
                        </div>
                      )}
                    </div>
                  </div>

                  {/*FOR SMALL SCREENS*/}
                  <div className="block sm:hidden">
                    {hasNotifications ? (
                      <div>
                        <div>
                          <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={toggleSelectAll}
                            className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                          />
                          <span className="ml-2">Select All</span>
                        </div>

                        <div className="grid grid-cols-1 xs:grid-cols-2 gap-4">
                          {allNotifications.map((notification, index) => (
                            <div
                              key={index}
                              className={`p-2 rounded-lg ${
                                index % 2 === 0
                                  ? "bg-mainLightBrown3"
                                  : "bg-mainLightBrown1"
                              } text-center pt-2 pb-2 `}
                              onClick={() => {
                                setNotificationDetailsId(notification.id);
                                navigate("/notificationDetails");
                              }}
                            >
                              <h4 className="text-center text-base sm-text-lg md:text-xl mb-2">
                                {notification.event}
                              </h4>
                              <div className="flex justify-between">
                                <input
                                  onClick={(e) => e.stopPropagation()}
                                  type="checkbox"
                                  checked={selectedNotifications.some(
                                    (m) => m.id === notification.id
                                  )}
                                  onChange={(e) => {
                                    toggleNotificationSelection(notification);
                                  }}
                                  className="form-checkbox h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px]"
                                />
                                {notification.hasseen ? (
                                  <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-green-500">
                                    {" "}
                                  </div>
                                ) : (
                                  <div className="h-[3vw] w-[3vw] max-h-[20px] max-w-[20px] min-h-[15px] min-w-[15px] bg-red-400">
                                    {" "}
                                  </div>
                                )}
                              </div>
                              <div>{notification.time}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className="text-black p-2 bg-mainDarkGreen text-center">
                        You have no notifications yet!
                      </div>
                    )}
                  </div>
                </div>

                {selectedNotifications.length > 0 && (
                  <button
                    className="bg-red-500 text-white p-2 rounded"
                    onClick={() => deleteNotifications()}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
                <PaginationControls
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  totalPageCount={totalPageCount}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useContext, useState, useEffect } from "react";
import { CityConfigContext } from "..";

export function InstaBuildingCooldown({ build, builds_left }) {
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const { insta_builds_left, last_instacomplete } =
    useContext(CityConfigContext);
  const [coolDown, setCooldown] = useState(0);

  const calculateCooldown = () => {
    const now = new Date();
    const cooldownEnd = last_instacomplete.getTime() + 24 * 60 * 60 * 1000;
    return Math.max(0, cooldownEnd - now);
  };

  useEffect(() => {
    if (last_instacomplete) {
      setCooldown(calculateCooldown());
    }
  }, [last_instacomplete]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (coolDown > 0) {
        setCooldown((prev) => prev - 1000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [coolDown]);

  /*   useEffect(() => {
    if (coolDown === 0) {
      // Optionally, you can handle any actions here when the cooldown reaches zero
    }
  }, [coolDown]); */

  return (
    <div>
      {builds_left > 0 && coolDown <= 0 ? (
        <button
          onClick={build}
          className="bg-green-800 hover:bg-green-500 text-white font-semibold py-2 px-4 rounded transition duration-200"
        >
          Insta build
        </button>
      ) : (
        coolDown > 0 && <div>{formatTime(coolDown)}</div>
      )}
    </div>
  );
}

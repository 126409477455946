import { useContext, useEffect, useState } from "react";
import { InventorySpotCard } from "./InventorySpotCard";
import { AnimatePresence } from "framer-motion";
import { LootChestPrizeModal } from "../../modals/LootChestPrizeModal";
import { CityConfigContext } from "../../..";
import { toast } from "react-toastify";
import { TabItem } from "../../buildingInfos/Marketplace";
import { ChestInventoryDetails } from "./ChestInventoryDetails";
import { CasinoPuzzleComponent } from "./CasinoPuzzleComponent";

export function ChestInventory() {
  const { inCityId } = useContext(CityConfigContext);
  const [activeTab, setActiveTab] = useState("inventory");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const options = [
    { value: "inventory", text: "Inventory" },
    { value: "puzzle", text: "Puzzle" },
  ];

  const [items, setItems] = useState([]);
  const [unclaimedPrize, setUnclaimedPrize] = useState();

  async function fetchInventory() {
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/inventory/${inCityId}/all`,
      { credentials: "include" }
    );

    if (res.status === 200) {
      const data = await res.json();

      updateInventory(data);
    }
  }

  async function checkUnclaimedPrize() {
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/casino/inventory/${inCityId}/unclaimed`,
      { credentials: "include" }
    );

    if (res.status === 200) {
      const data = await res.json();

      setUnclaimedPrize(data);
    }
  }

  function setNewUnclaimedPrize(prizeItem) {
    setUnclaimedPrize(prizeItem);
  }

  function updateInventory(inventoryData) {
    const openSpotsInInventory = 18 - inventoryData.amount_of_chests;
    const newInventory = [];
    // fill up array with inventory
    for (let i = 0; i < inventoryData.amount_of_chests; i++) {
      newInventory.push("Chest");
    }

    for (let i = 0; i < inventoryData.amount_of_v2_chests; i++) {
      newInventory.push("ChestV2");
    }
    // Fill the rest of the inventory with empty spots
    for (let i = 0; i < openSpotsInInventory; i++) {
      newInventory.push("empty");
    }
    setItems(newInventory);
  }
  async function claimPrize(claim_option) {
    if (claim_option === "claim") {
      const res = await fetch(
        `${
          import.meta.env.VITE_GAMEHOST
        }/api/casino/inventory/${inCityId}/claim`,
        {
          credentials: "include",
        }
      );
      if (res.ok) {
        const data = await res.text();
        toast(data);
      }
      setUnclaimedPrize(null);
    } else if (claim_option === "reroll") {
      const res = await fetch(
        `${
          import.meta.env.VITE_GAMEHOST
        }/api/casino/inventory/${inCityId}/claim?reroll=true`,
        {
          credentials: "include",
        }
      );
      if (res.status === 200) {
        const newInventory = [...items];
        const data = await res.text();
        const prize =
          data === "v1" ? "Chest" : data === "v2" ? "ChestV2" : null;

        // Find the first empty slot and replace it
        const emptyIndex = newInventory.indexOf("empty");
        if (emptyIndex !== -1 && prize) {
          newInventory[emptyIndex] = prize;
        }
        setItems(newInventory);
        setUnclaimedPrize(null);
      }
    }
  }

  useEffect(() => {
    checkUnclaimedPrize();
    fetchInventory();
  }, []);

  if (items.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <ul className="flex  text-lg sm:text:lg md:text-sm lg:text-base xl:text-lg text-white pb-4">
        <TabItem
          label="Inventory"
          active={activeTab === "inventory"}
          onClick={() => handleTabChange("inventory")}
        />
        <TabItem
          label="Puzzle"
          active={activeTab === "puzzle"}
          onClick={() => handleTabChange("puzzle")}
        />
      </ul>

      {activeTab === "inventory" && (
        <ChestInventoryDetails
          setNewUnclaimedPrize={setNewUnclaimedPrize}
          unclaimedPrize={unclaimedPrize}
          updateInventory={updateInventory}
          items={items}
          claimPrize={claimPrize}
        />
      )}
      {activeTab === "puzzle" && <CasinoPuzzleComponent />}
    </div>
  );
}

import { useContext, useEffect, useState } from "react";
import { NotificationContext } from "../index";

import { AttackDetailsCard } from "../Components/cards/AttackDetailsCard";

export function NotificationDetailsPage() {
  const { notificationDetailsId, setNotificationDetailsId } =
    useContext(NotificationContext);
  const [details, setDetails] = useState();
  const [loading, setLoading] = useState(true);

  async function getNotificationData() {
    const res = await fetch(
      `${
        import.meta.env.VITE_GAMEHOST
      }/api/notification/details/${notificationDetailsId}`,
      {
        credentials: "include",
      }
    );

    const data = await res.json();
    setDetails(data);
    setLoading(false);
  }

  useEffect(() => {
    if (notificationDetailsId) {
      localStorage.setItem("notificationId", notificationDetailsId);
      getNotificationData();
    } else {
      setNotificationDetailsId(localStorage.getItem("notificationId"));
      localStorage.removeItem("notificationId");
    }
  }, [notificationDetailsId]);

  if (loading) {
    return <div>Loading..</div>;
  }

  if (!details) {
    return;
  }

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[10vh]">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4 text-black">
          <div className="max-h-[66vh] overflow-y-auto bg-mainLightYellow p-4">
            <AttackDetailsCard data={details} />
          </div>
        </div>
      </div>
    </div>
  );
}

import { TownCenter } from "../Components/town/TownCenter";

export function TownPage() {
  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[10vh]">
        <TownCenter />
      </div>
    </div>
  );
}

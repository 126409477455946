import { useContext, useState } from "react";
import { UserContext } from "../index";
import { DiscordSettings } from "../Components/DiscordSettings";

export function SettingsPage() {
  const { userInfo } = useContext(UserContext);

  const [passwordFormData, setPasswordFormData] = useState({
    password: "",
    verify_password: "",
    old_password: "",
  });
  const [newEmail, setNewEmail] = useState(userInfo.email);
  const [passwordStatus, setPasswordStatus] = useState();
  const [emailStatus, setEmailStatus] = useState();

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordFormData({
      ...passwordFormData,
      [name]: value,
    });
  };
  const handlePasswordSubmit = async (e) => {
    setPasswordStatus(undefined);
    e.preventDefault();
    if (passwordFormData.password !== passwordFormData.verify_password) {
      setPasswordStatus("Passwords are not matching!");
      return;
    }
    if (
      passwordFormData.password.length < 6 ||
      passwordFormData.password.length > 50
    ) {
      setPasswordStatus("Between 6 and 50 characters needed!");
      return;
    }
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/change/password`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(passwordFormData),
        credentials: "include",
      }
    );
    if (res.status === 200) {
      setPasswordStatus("password changed");
    } else if (res.status === 401) {
      const data = await res.text();
      setPasswordStatus(data);
    } else {
      setPasswordStatus("Issue changing password");
    }
  };

  const resendVerificationEmail = async () => {
    setEmailStatus(undefined);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/resend/verification`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ email: userInfo.email }),
      }
    );
    if (res.ok) {
      setEmailStatus("Resent verification email!");
    } else {
      const data = await res.text();
      setEmailStatus(data);
    }
  };

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setNewEmail(value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (newEmail === userInfo.email) {
      setEmailStatus("You already have this email!");
      return;
    }
    setEmailStatus(undefined);
    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/auth/change/email`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ newEmail }),
        credentials: "include",
      }
    );

    if (res.status === 200) {
      setEmailStatus(
        "Email changed, an email has been sendt to verify your new email."
      );
    } else if (res.status === 401) {
      const data = await res.text();
      setEmailStatus(data);
    } else {
      setEmailStatus("Something went wrong!");
    }
  };

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-auto">
      <div className="relative  top-[10vh]">
        <div className="md:w-[50vw] mx-auto bg-mainDarkBrown p-4">
          <div className="bg-mainLightYellow grid lg:grid-cols-2">
            <div>
              {/*CHANGE EMAIL*/}
              <div>
                <form
                  className="lg:max-w-sm p-4 shadow-md rounded-lg"
                  onSubmit={handleEmailSubmit}
                >
                  <h2 className="text-2xl font-semibold mb-4 text-center">
                    Change email
                  </h2>

                  <div className="mb-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      New email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={handleEmailChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                      value={newEmail}
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Change Email
                  </button>
                  {emailStatus && <p className="text-red-500">{emailStatus}</p>}
                  {userInfo.temp_email && (
                    <div>
                      <h4 className="text-red-500">Email not verified</h4>
                      <button onClick={resendVerificationEmail}>
                        Resend verification email
                      </button>
                    </div>
                  )}
                </form>
                {/*  <h4>Settings for {userInfo.username}</h4>
                    <h4>
                        Change email
                    </h4>
                    <input type="text" />*/}
              </div>

              {/*CHANGE PASSWORD*/}
              <div>
                <form
                  onSubmit={handlePasswordSubmit}
                  className="lg:max-w-sm p-4 shadow-md rounded-lg"
                >
                  <h2 className="text-2xl font-semibold mb-4 text-center">
                    Change Password
                  </h2>

                  <div className="mb-4">
                    <label
                      htmlFor="old_password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Old Password
                    </label>
                    <input
                      type="password"
                      name="old_password"
                      id="old_password"
                      onChange={handlePasswordChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                      required
                    />
                  </div>

                  <div className="mb-4">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      New Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      id="password"
                      onChange={handlePasswordChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                      required
                    />
                  </div>

                  <div className="mb-6">
                    <label
                      htmlFor="verify_password"
                      className="block text-sm font-medium text-gray-700 mb-1"
                    >
                      Verify Password
                    </label>
                    <input
                      type="password"
                      name="verify_password"
                      id="verify_password"
                      onChange={handlePasswordChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-200"
                      required
                    />
                  </div>

                  <button
                    type="submit"
                    className="w-full py-2 px-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Change Password
                  </button>
                  {passwordStatus && (
                    <p className="text-red-500">{passwordStatus}</p>
                  )}
                </form>
              </div>
            </div>

            {/*DISCORD SETTINGS*/}

            {userInfo.linked_discord ? (
              <div>
                <DiscordSettings />
              </div>
            ) : (
              <div className="mt-6">
                Join the{" "}
                <a target="_blank" href="https://discord.gg/HbtAvbdkJ7">
                  Discord server
                </a>{" "}
                to get notified real time of attacks, and interact with the
                Siege bot for more functionality.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { useContext } from "react";
import { CityToken } from "./CityToken";
import { CityConfigContext } from "../index";
import { Tooltip } from "react-tooltip";

export function CityTokensComponent() {
  const { cityTokens } = useContext(CityConfigContext);
  CityConfigContext;

  if (!cityTokens) {
    return <div>Loading...</div>;
  }
  return (
    <div className="grid grid-cols-4 gap-2 p-2">
      <a
        className="my-anchor-element text-white"
        data-tooltip-content="Fills your warehouse to the maximum capacity."
      >
        {cityTokens.fill_warehouse_count > 0 && (
          <CityToken
            category="fill_warehouse_count"
            value={cityTokens.fill_warehouse_count}
          />
        )}
      </a>

      {cityTokens.day_hour_resource_boost_count > 0 && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content="Boosts resource production for 24 hours."
        >
          <CityToken
            category="day_hour_resource_boost_count"
            value={cityTokens.day_hour_resource_boost_count}
          />
        </a>
      )}

      {cityTokens.day_hour_troop_training_boost_count && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content="Speeds up troop training for 24 hours."
        >
          <CityToken
            category="day_hour_troop_training_boost_count"
            value={cityTokens.day_hour_troop_training_boost_count}
          />
        </a>
      )}

      {cityTokens.day_hour_extra_building_in_queue_count > 0 && (
        <a
          className="my-anchor-element text-white"
          data-tooltip-content="Allows you to queue an additional building for 24 hours."
        >
          <CityToken
            category="day_hour_extra_building_in_queue_count"
            value={cityTokens.day_hour_extra_building_in_queue_count}
          />
        </a>
      )}
      <Tooltip anchorSelect=".my-anchor-element"></Tooltip>
    </div>
  );
}

import Warlord from "./WarlordIcon.png";

export function WarlordResponsive() {
  return (
    <img
      src={Warlord}
      alt={`Warlord icon`}
      className="w-6 h-6 min-w-[20px] xs:w-8 xs:h-8 mr-2"
    />
  );
}

import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../../index";
import { NextTroopInQueueModal } from "../cards/NextTroopInQueueModal";
import { NextFounderInQueueModal } from "../cards/NextFounderInQueueModal";
import { ClubmanResponsive } from "../../images/troops/ClubmanResponsive";
import { SpearmanResponsive } from "../../images/troops/SpearmanResponsive";
import { AxemanResponsive } from "../../images/troops/AxemanResponsive";
import { FounderIconResponsive } from "../../images/troops/FounderIconResponsive";
import { GuardianResponsive } from "../../images/troops/GuardianResponsive";
import { WarlordResponsive } from "../../images/troops/WarlordResponsive";
import { ScoutResponsive } from "../../images/troops/ScoutResponsive";
import { CatapultResponsive } from "../../images/troops/CatapultResponsive";
import { ConquererResponsive } from "../../images/troops/ConquererResponsive";

export function TroopsOverview() {
  const { troops } = useContext(CityConfigContext);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (troops) {
      setShouldShow(false);
      troops.forEach((troop) => {
        if (troop.in_city_amount > 0) {
          setShouldShow(true);
        }
      });
    }
  }, [troops]);

  if (!troops) {
    return <div>You have no troops in this city</div>;
  }
  if (!shouldShow) {
    return null;
  }

  return (
    <div className="bg-mainDarkBrown text-white p-2 min-w-[170px] sm:min-w-[170px] md:min-w-full lg:min-w-auto xl:min-w-auto">
      <h2 className="font-fantasy text-xs lg:text-base xxl:text-xl text-white font-semibold mb-2 sm:mb-4 md:mb-4 lg:mb-4 xl:mb-4 text-center">
        Troops
      </h2>
      <div className="p-2 sm:p-3 md:p-3 lg:p-6 bg-mainLightBrown1 text-black overflow-auto max-h-[20vh] scrollbar scrollbar-thumb-cityCard3 scrollbar-thin">
        <ul className="space-y-1 sm:space-y-2 md:space-y-2 lg:space-y-2 xl:space-y-2 font-pixel grid grid-cols-2 md:grid-cols-1">
          {troops.map((troop) =>
            troop.in_city_amount > 0 ? (
              <li className="flex flex-col md:flex-row" key={troop.type}>
                {troop.type === "Clubman" && <ClubmanResponsive />}
                {troop.type === "Spearman" && <SpearmanResponsive />}
                {troop.type === "Axeman" && <AxemanResponsive />}
                {troop.type === "Founder" && <FounderIconResponsive />}
                {troop.type === "Guardian" && <GuardianResponsive />}
                {troop.type === "Warlord" && <WarlordResponsive />}
                {troop.type === "Scout" && <ScoutResponsive />}
                {troop.type === "Catapult" && <CatapultResponsive />}
                {troop.type === "Conquerer" && <ConquererResponsive />}
                <h2 className="text-base sm:text-base md:text-sm lg:text-base xl:text-lg">
                  {troop.in_city_amount}
                </h2>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </div>
  );
}

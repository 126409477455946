import { useContext, useEffect, useState } from "react";
import { CityConfigContext, UserContext } from "..";
import { HelperFunctions } from "../HelperFunctions";

export function OccupyCityComponent() {
  const { cityListState } = useContext(UserContext);
  const { inCityId } = useContext(CityConfigContext);
  const [occupiedUntil, setOccupiedUntil] = useState(null);
  const [secondsLeft, setSecondsLeft] = useState(0);
  const [isOccupier, setIsOccupier] = useState(false);

  useEffect(() => {
    if (cityListState && cityListState.length > 0) {
      cityListState.forEach((item) => {
        if (item.isOccupied && item.cityid === inCityId) {
          if (item.isOccupier) {
            setIsOccupier(true);
          }
          const endTime = new Date(item.endtime);

          setOccupiedUntil(endTime);
          const secondsLeft = Math.floor((endTime - new Date()) / 1000);
          setSecondsLeft(secondsLeft);
        }
      });
    }
  }, [cityListState, inCityId]);

  useEffect(() => {
    if (!occupiedUntil) return;

    const interval = setInterval(() => {
      const now = new Date();
      const remainingSeconds = Math.floor((occupiedUntil - now) / 1000);
      setSecondsLeft(Math.max(remainingSeconds, 0)); // Ensure no negative seconds
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [occupiedUntil]);

  return (
    <div className="w-screen h-screen bg-gradient-to-b from-mainLightGreen to-[#d4a373] overflow-hidden">
      <div className="relative top-[10vh] flex justify-center items-center">
        <div className="w-11/12 md:w-[50vw] bg-mainDarkBrown shadow-xl p-4">
          <div className="bg-mainLightYellow rounded-lg p-4 shadow-inner border-4 border-mainDarkBrown max-h-[74vh] overflow-auto">
            <h2 className="text-3xl font-bold text-center text-mainDarkBrown mb-4">
              {isOccupier ? "Your Occupation!" : "City Occupied!"}
            </h2>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-mainDarkBrown">
              {/* Left Column: Explanation */}
              <div className="p-4 rounded-lg shadow bg-mainLightBrown2">
                <h3 className="text-xl font-semibold mb-2">
                  {isOccupier ? "Hold the City!" : "Reclaim the City!"}
                </h3>
                <p className="text-lg">
                  {isOccupier
                    ? "Defend the city from all attacks until the timer expires. Reinforce its defenses to secure victory!"
                    : "Defeat all enemy troops before the timer runs out to stop the occupation and reclaim the city!"}
                </p>
              </div>

              {/* Right Column: Timer */}
              <div className="p-4 bg-mainLightBrown2 rounded-lg shadow">
                {occupiedUntil ? (
                  <>
                    <h3 className="text-xl font-semibold mb-2">
                      Occupation Timer
                    </h3>
                    <p className="text-lg">
                      <strong>Occupied until:</strong>{" "}
                      {occupiedUntil.toLocaleString()}
                    </p>
                    <p className="text-lg">
                      <strong>Remaining time:</strong>{" "}
                      {HelperFunctions.secondsToTimestamp(secondsLeft)}
                    </p>
                  </>
                ) : (
                  <p className="text-lg">Timer not available</p>
                )}
              </div>
            </div>

            {/* Action Suggestion */}
            {isOccupier && (
              <div className="mt-6 p-4 bg-mainDarkBrown  rounded-lg shadow">
                <h3 className="text-xl font-semibold mb-2">Strategy Tip</h3>
                <p className="text-lg">
                  Send reinforcements to strengthen the city's defenses and
                  ensure your victory!
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

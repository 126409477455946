import { useContext } from "react";
import { CityConfigContext, UserContext } from "../../index";
import { QuestInfoCard } from "../cards/QuestInfoCard";

export function QuestDescriptionModal({ closeModal }) {
  const { playerQuest } = useContext(UserContext);
  const { inCityId } = useContext(CityConfigContext);

  async function startTutorial() {
    await fetch(`${import.meta.env.VITE_GAMEHOST}/api/tutorial/start`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify({ cityId: inCityId }),
      credentials: "include",
    });
  }

  return (
    <div
      className="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        >
          {" "}
        </div>

        <div className="inline-block rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-[75vw]  lg:w-[50vw] ">
          {!playerQuest ? (
            <div className=" bg-gray-900 p-5 ">
              <div className="bg-gray-800 text-white rounded-lg shadow-lg overflow-hidden sm:max-w-2xl sm:w-full flex sm:flex-row-reverse items-center justify-center">
                <button
                  type="button"
                  className=" mr-2 md:mr-0 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={startTutorial}
                >
                  Start tutorial
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </div>
          ) : (
            <QuestInfoCard playerQuest={playerQuest} closeModal={closeModal} />
          )}
        </div>
      </div>
    </div>
  );
}

import { useContext, useState } from "react";
import { CityConfigContext } from "../../..";

export function PuzzleStoreOverview({ puzzle, setPuzzle }) {
  const [status, setStatus] = useState();
  const { inCityId } = useContext(CityConfigContext);
  const storeItems = [
    {
      title: "10 Mill Resources",
      description: "Boost your empire with a vast amount of resources.",
      color: "text-purple-400",
      category: "resources",
    },
    {
      title: "25,000 Axemen",
      description: "Reinforce your army with skilled warriors.",
      color: "text-purple-400",
      category: "troops",
    },
    {
      title: "4,000 Casino Coins",
      description: "Try your luck in the casino for epic rewards!",
      color: "text-purple-400",
      category: "coins",
    },
  ];

  const [selectedItem, setSelectedItem] = useState();

  const handleSelect = (index) => {
    if (index === selectedItem) {
      setSelectedItem(undefined);
    } else {
      setSelectedItem(index);
    }
  };

  const handleBuy = async () => {
    setStatus(undefined);

    // Make sure city has one or more of each puzzle piece.
    if (
      puzzle.pieceACount <= 0 ||
      puzzle.pieceBCount <= 0 ||
      puzzle.pieceCCount <= 0
    ) {
      setStatus("You need one of each piece to purchase this!");
      return;
    }

    if (selectedItem === undefined || selectedItem === null) {
      setStatus("Chose an item to purchase");
      return;
    }
    const obj = { item: storeItems[selectedItem].category };

    const res = await fetch(
      `${import.meta.env.VITE_GAMEHOST}/api/casino/puzzle/buy/${inCityId}`,
      {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(obj),
        credentials: "include",
      }
    );
    if (!res.ok) {
      const data = await res.text();
      setStatus(data);
    } else {
      const data = await res.json();
      setPuzzle(data);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-800 rounded-lg shadow-lg">
      <h4 className="text-4xl font-bold text-yellow-400 mb-6 text-center">
        Puzzle Store
      </h4>

      <h5 className="text-lg font-semibold text-gray-300 mb-4 text-center">
        Trade one set of pieces for:
      </h5>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6">
        {storeItems.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSelect(index)}
            className={`relative bg-gray-700 p-4 rounded-lg text-center hover:bg-gray-600 transition cursor-pointer ${
              selectedItem === index ? "border-2 border-yellow-400" : ""
            }`}
          >
            <div>
              <h6 className={`text-xl font-bold ${item.color}`}>
                {item.title}
              </h6>
              <p className="text-sm text-gray-300 mt-2">{item.description}</p>
            </div>
          </div>
        ))}
      </div>

      {selectedItem >= 0 && (
        <div className="text-center mt-6">
          <button
            onClick={handleBuy}
            className="bg-yellow-400 text-gray-800 font-bold py-2 px-4 rounded-lg hover:bg-yellow-500 transition"
          >
            Buy
          </button>
        </div>
      )}
      {status && <h4 className="text-red-600">{status}</h4>}
    </div>
  );
}

import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import { CASINO_COINS_BUY_PRICE, CASINO_COINS_SELL_PRICE } from "../../RULES";
import { useContext, useEffect, useState } from "react";
import { CityConfigContext } from "../..";

export function ResourcePriceCard(props) {
  const { cityBonus } = useContext(CityConfigContext);

  const [wheatSellValue, setWheatSellValue] = useState(
    CASINO_COINS_SELL_PRICE.Wheat
  );
  const [woodSellValue, setWoodSellValue] = useState(
    CASINO_COINS_SELL_PRICE.Wood
  );
  const [stoneSellValue, setStoneSellValue] = useState(
    CASINO_COINS_SELL_PRICE.Stone
  );
  const [ironSellValue, setIronSellValue] = useState(
    CASINO_COINS_SELL_PRICE.Iron
  );
  useEffect(() => {
    if (cityBonus) {
      let abilityIsActive = false;
      if (cityBonus.active_abilities.length > 0) {
        cityBonus.active_abilities.forEach((item) => {
          if (item.name === "2x Coins") {
            abilityIsActive = true;
          }
        });
      }
      if (abilityIsActive) {
        setWheatSellValue(CASINO_COINS_SELL_PRICE.Wheat * 2);
        setWoodSellValue(CASINO_COINS_SELL_PRICE.Wood * 2);
        setStoneSellValue(CASINO_COINS_SELL_PRICE.Stone * 2);
        setIronSellValue(CASINO_COINS_SELL_PRICE.Iron * 2);
      } else {
        setWheatSellValue(CASINO_COINS_SELL_PRICE.Wheat);
        setWoodSellValue(CASINO_COINS_SELL_PRICE.Wood);
        setStoneSellValue(CASINO_COINS_SELL_PRICE.Stone);
        setIronSellValue(CASINO_COINS_SELL_PRICE.Iron);
      }
    }
  }, [cityBonus]);

  return (
    <div className="grid grid-cols-2 xxxs:grid-cols-4 gap-2">
      <div className="mr-4 flex">
        <WheatIconResponsive />
        {props.priceType === "buy" ? (
          <h4>{CASINO_COINS_BUY_PRICE.Wheat}</h4>
        ) : (
          <h4>{wheatSellValue}</h4>
        )}
      </div>

      <div className="mr-4 flex">
        <WoodIconReponsive />
        {props.priceType === "buy" ? (
          <h4>{CASINO_COINS_BUY_PRICE.Wood}</h4>
        ) : (
          <h4>{woodSellValue}</h4>
        )}
      </div>

      <div className="mr-4 flex">
        <StoneIconResponsive />
        {props.priceType === "buy" ? (
          <h4>{CASINO_COINS_BUY_PRICE.Stone}</h4>
        ) : (
          <h4>{stoneSellValue}</h4>
        )}
      </div>

      <div className="mr-4 flex">
        <IronIconResponsive />
        {props.priceType === "buy" ? (
          <h4>{CASINO_COINS_BUY_PRICE.Iron}</h4>
        ) : (
          <h4>{ironSellValue}</h4>
        )}
      </div>
    </div>
  );
}

import { useContext } from "react";

import { WheatIconResponsive } from "../../images/resources/WheatIconResponsive";
import { WoodIconReponsive } from "../../images/resources/WoodIconReponsive";
import { StoneIconResponsive } from "../../images/resources/StoneIconResponsive";
import { IronIconResponsive } from "../../images/resources/IronIconResponsive";
import {
  useIncomingResourcesTimerState,
  useRetrieveTraderTimerState,
  useSendResourceTimerState,
} from "../contexts/timerContext";
import { UserContext } from "../../index";
import { useNavigate } from "react-router-dom";
import { HelperFunctions } from "../../HelperFunctions";

export function MarketplaceTimerList() {
  const sendResourceTimers = useSendResourceTimerState();
  const returningTraderTimers = useRetrieveTraderTimerState();
  const incomingResourcesTimers = useIncomingResourcesTimerState();
  const { userInfo } = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <div className=" w-full grid ">
      {sendResourceTimers.length > 0 && (
        <div className="max-h-[20vh] lg:max-h-[35vh] overflow-y-auto">
          <div className=" p-2">
            <h4 className="text-xl font-semibold mb-2">Timers</h4>
            {sendResourceTimers.map((item) => (
              <div>
                <div className=" mb-2 p-2 bg-mainLightBrown4 rounded">
                  <div className="bg-mainLightBrown1 rounded p-2">
                    <h4 className="flex justify-center">
                      To {item.extra.toCityName}{" "}
                      <a
                        onClick={() =>
                          navigate(
                            `/profile/${
                              item.extra.toUserName === userInfo.username
                                ? `me`
                                : item.extra.toUserName
                            }`
                          )
                        }
                        className="inline"
                      >
                        ({item.extra.toUserName})
                      </a>
                    </h4>

                    <div className="grid grid-cols-2 xxxs:grid-cols-4">
                      <div className="flex">
                        <WheatIconResponsive />
                        <h4 className="ml-2">{item.extra.wheat}</h4>
                      </div>
                      <div className="flex">
                        <WoodIconReponsive />
                        <h4 className="ml-2">{item.extra.wood}</h4>
                      </div>
                      <div className="flex">
                        <StoneIconResponsive />
                        <h4 className="ml-2">{item.extra.stone}</h4>
                      </div>
                      <div className="flex">
                        <IronIconResponsive />
                        <h4 className="ml-2">{item.extra.iron}</h4>
                      </div>
                    </div>
                    <div>
                      <h4>
                        {HelperFunctions.secondsToTimestamp(item.timeLeft)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {incomingResourcesTimers.length > 0 && (
        <div className="max-h-[20vh] lg:max-h-[35vh] overflow-y-auto">
          <div className=" p-2">
            <h4 className="text-xl font-semibold mb-2">Timers</h4>
            {incomingResourcesTimers.map((item) => (
              <div>
                <div className="bg-mainLightBrown4 rounded mb-2 p-2">
                  <div className="bg-mainLightBrown1 rounded p-2">
                    <h4 className="flex justify-center">
                      From {item.extra.toCityName}
                      <a
                        className="inline"
                        onClick={() =>
                          navigate(
                            `/profile/${
                              item.extra.fromUserName === userInfo.username
                                ? `me`
                                : item.extra.fromUserName
                            }`
                          )
                        }
                      >
                        {" "}
                        ({item.extra.fromUserName})
                      </a>
                    </h4>

                    <div className="grid grid-cols-2 xxxs:grid-cols-4">
                      <div className="flex">
                        <WheatIconResponsive />
                        <h4 className="ml-2">{item.extra.wheat}</h4>
                      </div>
                      <div className="flex">
                        <WoodIconReponsive />
                        <h4 className="ml-2">{item.extra.wood}</h4>
                      </div>
                      <div className="flex">
                        <StoneIconResponsive />
                        <h4 className="ml-2">{item.extra.stone}</h4>
                      </div>
                      <div className="flex">
                        <IronIconResponsive />
                        <h4 className="ml-2">{item.extra.iron}</h4>
                      </div>
                    </div>
                    <div>
                      <h4>
                        {HelperFunctions.secondsToTimestamp(item.timeLeft)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {returningTraderTimers.length > 0 && (
        <div>
          {returningTraderTimers.map((item) => (
            <div>
              <div className="bg-mainLightBrown4 rounded mb-2 p-2">
                <div className="bg-mainLightBrown1 rounded p-2">
                  <h4 className="flex justify-center">
                    Returning from {item.extra.fromCityName}
                    <a
                      className="inline"
                      onClick={() =>
                        navigate(
                          `/profile/${
                            item.extra.fromUserName === userInfo.username
                              ? `me`
                              : item.extra.fromUserName
                          }`
                        )
                      }
                    >
                      {" "}
                      ({item.extra.fromUserName})
                    </a>
                  </h4>
                  <h4>Traders: {item.extra.tradersNeeded}</h4>
                  <h4>{HelperFunctions.secondsToTimestamp(item.timeLeft)}</h4>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
